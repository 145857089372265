// src/slices/availableRecurringDaysSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const availableRecurringDaysSlice = createSlice({
  name: 'availableRecurringDays',
  initialState,
  reducers: {
    setAvailableRecurringDays: (state, action) => {
      return [...action.payload];
    },
    resetAvailableRecurringDays: () => {
      return [];
    },
  },
});

export const { setAvailableRecurringDays, resetAvailableRecurringDays } = availableRecurringDaysSlice.actions;
export default availableRecurringDaysSlice.reducer;
