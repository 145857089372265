// src/screens/SummaryScreen.js
import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../components/Header';
import GoogleMapComponent from '../components/GoogleMapComponent';
import Checkbox from '../components/Checkbox';
import ImageRow from '../components/ImageRow';
import CreditCardType from '../components/CreditCardType';
import PriceEstimateDisplay from '../components/PriceEstimateDisplay';

import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';
import { getFirebaseToken } from '../utils/firebaseTokenUtil';

import { constructItemsFromTasks } from '../utils/taskUtils';

import './SummaryScreen.css';



// Formats a date string to a more readable form
const formatDate = (dateString) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // Split the dateString and create a Date object treating it as local time
  const [year, month, day] = dateString.split('-').map(str => parseInt(str, 10));
  const date = new Date(year, month - 1, day); // Month is 0-indexed

  const dayName = days[date.getDay()];
  const monthName = months[date.getMonth()];
  const dayOfMonth = date.getDate();

  const ordinal = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return `${dayName}, ${monthName} ${ordinal(dayOfMonth)}`;
};


  // Formats the cleaning type to a more readable form
  const formatCleaningType = (type) => {
    switch(type) {
      case 'standard':
        return 'Standard Clean';
      case 'deep':
        return 'Deep Clean';
      case 'moveout':
        return 'Move Out Clean';
      default:
        return type; // Return the original string if no match is found
    }
};


// Compiles service frequency text
const getServiceFrequencyText = (serviceFrequency, desiredRecurringDay) => {
  let result = [];

  switch (serviceFrequency) {
    case 'once':
      result.push({ text: 'One time service' });
      break;
    case 'monthly':
      result.push({ title: 'Frequency', text: 'Monthly' });
      break;
    case 'biweekly':
      result.push({ title: 'Frequency', text: 'Bi-Weekly' });
      break;
    case 'weekly':
      result.push({ title: 'Frequency', text: 'Weekly' });
      break;
    default:
      // Handle default case
  }

  if (serviceFrequency !== 'once' && desiredRecurringDay) {
    result.push({ title: 'Default Day', text: desiredRecurringDay });
  }

  return result;
};


const calculateRevisedPriceDetails = (job, serviceFrequency) => {
  const {
    estimatedCleanTime,
    numberOfCleaners,
    regularBasePriceEstimate,
    multiShiftType,
    fullShiftOnlyEstimatedCleanTime,
    fullShiftOnlyNumberOfCleaners,
    fullShiftBasePriceEstimate,   
    createdByRecurringScheduler
  } = job;

  let usedEstimatedCleanTime, usedNumberOfCleaners, usedBasePriceEstimate;

  if (numberOfCleaners === 1) {
    usedEstimatedCleanTime = estimatedCleanTime;
    usedNumberOfCleaners = numberOfCleaners;
    usedBasePriceEstimate = regularBasePriceEstimate;
  } else {
    usedEstimatedCleanTime = multiShiftType === 'fullLarge' ? fullShiftOnlyEstimatedCleanTime : estimatedCleanTime;
    usedNumberOfCleaners = multiShiftType === 'fullLarge' ? fullShiftOnlyNumberOfCleaners : numberOfCleaners;
    usedBasePriceEstimate = multiShiftType === 'fullLarge' ? fullShiftBasePriceEstimate : regularBasePriceEstimate;
  }

  return {
    cleanerQuantity: numberToWord(usedNumberOfCleaners),
    timeEstimate: formatTime(usedEstimatedCleanTime),
    priceTitle: "Projected cost w/ tax",
    price: Math.round(usedBasePriceEstimate)
  };
};

  
// Converts numerical numbers to word form up to 10
const numberToWord = (num) => {
  const words = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];

  if (num >= 0 && num <= 10) {
    return num === 1 ? `${words[num]} cleaner` : `${words[num]} cleaners`;
  } else if (num > 10) {
    return `${num} cleaners`;
  } else {
    return 'invalid number';
  }
};


// Converts decimal hours to hours and minutes, rounding to the nearest 5 minutes
const formatTime = (decimalHours) => {
  let hours = Math.floor(decimalHours);
  let minutes = Math.round((decimalHours - hours) * 60 / 5) * 5; // Round to the nearest 5 minutes

  // If rounding results in 60 minutes, adjust to the next hour and set minutes to 0
  if (minutes === 60) {
      hours += 1;
      minutes = 0;
  }

  let timeStr = '';
  if (hours > 0) {
      timeStr += `${hours} hour${hours === 1 ? '' : 's'}`;
      // Only add comma if there are minutes to display
      if (minutes > 0) {
      timeStr += `, `;
      }
  }

  if (minutes > 0) {
      timeStr += `${minutes} minute${minutes === 1 ? '' : 's'}`;
  }

  return timeStr;
};


const SummaryScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  

  // Get the passed state (if any) or set a default value
  const orderSubmittedBinary = location.state?.orderSubmittedBinary ?? false;

  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();  
  const property = useSelector((state) => state.property);
  const latitude = useSelector((state) => state.property.latitude);
  const longitude = useSelector((state) => state.property.longitude);

  const address = property.address;
  const city = property.city;
  const propertyState = property.propertyState;
  const zipCode = property.zipCode;
  const apartmentName = property.apartmentName;
  const apartmentUnit = property.apartmentUnit;
  const propertyType = property.propertyType;

  const meetOwnerAtDoorBinary = useSelector((state) => state.property.meetOwnerAtDoorBinary);
  const hiddenKeyBinary = useSelector((state) => state.property.hiddenKeyBinary);
  const unlockedBinary = useSelector((state) => state.property.unlockedBinary);
  const gateCommunityBinary = useSelector((state) => state.property.gateCommunityBinary);

  const accessOptions = [
    { label: 'Meet owner at front door on arrival', checked: meetOwnerAtDoorBinary },
    { label: 'Hidden key', checked: hiddenKeyBinary },
    { label: 'Unlocked', checked: unlockedBinary },
    { label: 'Gated community', checked: gateCommunityBinary },
  ];

  const hasAccessDetail = accessOptions.some(option => option.checked);

  const job = useSelector(state => state.job);
  const jobId = useSelector((state) => state.job.id);
  const userId = useSelector((state) => state.user.id);

  const cleaningType = useSelector(state => state.job.cleaningType);

  const multiShiftType = useSelector((state) => state.job.multiShiftType);
  const createdByRecurringScheduler = useSelector(state => state.job.createdByRecurringScheduler);  
  const desiredDate = useSelector(state => state.desiredDate);
  const desiredCleaners = useSelector(state => state.desiredCleaners);
  const desiredRecurringDay = useSelector(state => state.desiredRecurringDay);
  const serviceFrequency = useSelector(state => state.serviceFrequency);

  const selectedCardInformation = useSelector(state => state.selectedCardInformation);

  const subscribedTaskImages = useSelector(state => state.subscribedTasks);
  const tasks = useSelector(state => state.tasks);

  // Access individual card details
  const brand = selectedCardInformation?.brand;
  const last4 = selectedCardInformation?.last4;
  const expMonth = selectedCardInformation?.expMonth;
  const expYear = selectedCardInformation?.expYear;

  //render elemenets
  const itemsForImageRow = constructItemsFromTasks(tasks, subscribedTaskImages);
  const recurringServiceText = getServiceFrequencyText(serviceFrequency, desiredRecurringDay);


  const user = useSelector((state) => state.user);
  const adminTargetUser = useSelector((state) => state.adminTargetUser);

  

  // const scheduleServiceBackend = async () => {
  //   setIsProcessing(true); // Start processing and show loading indicator
  
  //   try {
  //     const firebaseIdToken = await getFirebaseToken();
  //     if (!firebaseIdToken) {
  //         throw new Error('No Firebase ID token');
  //     }

  //     const scheduleServiceData = {
  //       desiredDate: desiredDate,
  //       desiredRecurringDay: desiredRecurringDay,
  //       serviceFrequency: serviceFrequency,
  //       multiShiftType: multiShiftType,
  //       jobId: jobId,
  //       userId: userId, 
  //       selectedRecurringCleaners: desiredCleaners, 
  //     };
  
  //     const response = await axios.post(`${apiBaseUrl}/schedule-service`, scheduleServiceData, {
  //       headers: { Authorization: `Bearer ${firebaseIdToken}` }
  //     });
  
  //     if (response.status === 200) {
  //       console.log('Service scheduled successfully:', response);
  //       navigate('/servicehome');

  //     } else {
  //       console.error('Error scheduling service:', response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error scheduling service:', error.message || error);
  //   } finally {
  //     setIsProcessing(false); // End processing regardless of outcome
  //   }
  // };


  const scheduleServiceBackend = async () => {
    setIsProcessing(true); // Start processing and show loading indicator
  
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        throw new Error('No Firebase ID token');
      }
  
      // Determine the effective userId
      const effectiveUserId = user.role === 'owner' && adminTargetUser.id ? adminTargetUser.id : userId;
  
      // Prepare the request body
      const scheduleServiceData = {
        desiredDate: desiredDate,
        desiredRecurringDay: desiredRecurringDay,
        serviceFrequency: serviceFrequency,
        multiShiftType: multiShiftType,
        jobId: jobId,
        userId: effectiveUserId, // Use the dynamically determined userId
        selectedRecurringCleaners: desiredCleaners,
      };
  
      // Add adminUserIdQuery if impersonation is active
      if (user.role === 'owner' && adminTargetUser.id) {
        scheduleServiceData.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }
  
      // Make the API call
      const response = await axios.post(`${apiBaseUrl}/schedule-service`, scheduleServiceData, {
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
      });
  
      if (response.status === 200) {
        console.log('Service scheduled successfully:', response);
        navigate('/servicehome');
      } else {
        console.error('Error scheduling service:', response.data.message);
      }
    } catch (error) {
      console.error('Error scheduling service:', error.message || error);
    } finally {
      setIsProcessing(false); // End processing regardless of outcome
    }
  };
  


  const formatNumberWithCommas = (number) => {
    if (!number) return 'N/A';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  const compileBedBathInfo = (property) => {
    if (!property) return 'N/A';

    const bedroomText = property.numberBedrooms === 1 ? '1 bedroom' : `${property.numberBedrooms} bedrooms`;
    const bathroomText = property.numberBathrooms === 1 ? '1 bath' : `${property.numberBathrooms} baths`;
    return `${bedroomText}, ${bathroomText}`;
  };

  const priceDetails = calculateRevisedPriceDetails(job, serviceFrequency);


  // Helper function to navigate back to home
  const returnHome = () => {
    navigate('/servicehome');
  };



  return (
    <div className="full-width-container with-padding-bottom">

      <Header />

      <div className="content-container column">

        <div className="form-container">
          <h1>Order Summary</h1>
        </div>

        <div className="summary-columns">
          {/* Column 1: Property Information */}
          <div className="summary-column">
            {/* Property Location Header */}
            <div className="summary-section-header" style={{ marginTop: 0 }} >Property Location</div>

            {/* Property Details Section */}
            <section className="summary-info-padding">
              <div>
                {/* Display Apartment Name if propertyType is 'apartment' */}
                {propertyType === 'apartment' && apartmentName && (
                  <div className="details-row">
                    <label>Apartment Name:</label>
                    <p>{apartmentName}</p>
                  </div>
                )}

                {/* Address Details */}
                <div className="details-row">
                  <label>Property Address:</label>
                  <p>
                    {address || 'N/A'}
                    {propertyType === 'apartment' && apartmentUnit ? `, Unit ${apartmentUnit}` : ''}
                  </p>
                  <p>{`${city || 'N/A'}, ${propertyState || 'N/A'} ${zipCode || 'N/A'}`}</p>
                </div>
              </div>
            </section>

            {/* Property & Access Details Header */}
            <div className="summary-section-header">Property Detail</div>

            {/* Property Access Details Section */}
            <section className="summary-info-padding">
              <div className="details-row">
                <label>Clean Area:</label>
              </div>

              <div className="details-row">
                <p>{compileBedBathInfo(property)}</p>
              </div>
              {property.squareFeet && (
                <div className="details-row">
                  <p>{formatNumberWithCommas(property.squareFeet)} square feet</p>
                </div>
              )}

              {/* Access Detail Section for Binary Options */}
              {hasAccessDetail && (
                <>
                  <div className="details-row">
                    <label>Access Detail:</label>
                  </div>
                  <div >
                    {accessOptions.map((option, index) => (
                      option.checked && (
                        <Checkbox
                          key={index}
                          label={option.label}
                          checked={option.checked}
                          readOnly={true}
                          fontSize="unset"
                        />
                      )
                    ))}
                  </div>
                </>
              )}

              {/* Access Instructions Section */}
              {property.propertyAccessNotes && (
                <>
                  <div className="details-row">
                    <label>Access Instructions:</label>
                  </div>
                  <div className="details-row">
                    <p>{property.propertyAccessNotes}</p>
                  </div>
                </>
              )}
            </section>

            {/* Property Pets Section */}
            {(property.numberDogs > 0 || property.numberCats > 0 || property.propertyPetsNotes) && (
              <>
                <div className="summary-section-header">Property Pets</div>
                <section className="summary-info-padding">
                  {property.numberDogs > 0 && (
                    <div className="details-row">
                      <p>{property.numberDogs === 1 ? '1 dog' : `${property.numberDogs} dogs`}</p>
                    </div>
                  )}
                  {property.numberCats > 0 && (
                    <div className="details-row">
                      <p>{property.numberCats === 1 ? '1 cat' : `${property.numberCats} cats`}</p>
                    </div>
                  )}
                  {property.propertyPetsNotes && (
                    <div className="details-row">
                      <p>{property.propertyPetsNotes}</p>
                    </div>
                  )}
                </section>
              </>
            )}
          </div>

          {/* Column 2: Google Map */}
          <div className="summary-column map-column">
            <GoogleMapComponent location={{ latitude, longitude }} />
          </div>
        </div>

        {/* New Row: Service Level Section */}
        <div>
          <div className="summary-section-header">Service Level</div>
          <div className="service-level-container">
            <p className="service-level-text">{formatCleaningType(cleaningType)}</p>
          </div>
        </div>

        {/* New Row: Custom To Do Tasks Section */}
        <div >
          <div className="summary-section-header">Custom To Do Tasks</div>
          <div
            className="custom-todo-container"
            onClick={() => navigate('/todo-list')}
          >
            <ImageRow
              items={itemsForImageRow}
              placeholderImageType="customerTasks"
              isNavigationEnabled={false}
            />
          </div>
        </div>

        {/* New Row: Service Date Section */}
        <div>
          <div className="summary-section-header">Service Date</div>
          <div className="summary-info-padding details-row">
            <p>{formatDate(desiredDate)}</p>
          </div>
        </div>

        {/* New Row: Service Timing Section */}
        <div>
          <div className="summary-section-header">Service Timing</div>
          <div className="summary-info-padding">
            {recurringServiceText.map((item, index) => (
              <div key={index} className="details-row">
                <p>
                  {item.title ? (
                    <>
                      <span style={{ fontWeight: 'bold' }}>{item.title}:</span> {item.text}
                    </>
                  ) : (
                    item.text
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* New Row: Payment Method Section */}
        <div>
          <div className="summary-section-header">Payment Method</div>
          <div className="summary-info-padding">
            <CreditCardType
              creditCardType={brand}
              lastFour={last4}
              expMonth={expMonth}
              expYear={expYear}
              isClickable={false}
            />
          </div>
        </div>

        {/* New Row: Job Estimates & Price Section */}
        <div>
          <div className="summary-section-header">Job Estimates & Price</div>
          <div className="summary-info-padding">
            {/* Table for cleaner quantity and time estimate */}
            <table className="job-estimate-table">
              <tbody>
                <tr>
                  <td className="estimate-header">Number of Cleaners:</td>
                  <td className="estimate-content">{priceDetails.cleanerQuantity}</td>
                </tr>
                <tr>
                  <td className="estimate-header">Clean Time Estimate:</td>
                  <td className="estimate-content">{priceDetails.timeEstimate}</td>
                </tr>
              </tbody>
            </table>

            {/* Projected cost using PriceEstimateDisplay component, wrapped to constrain width */}
            <div className="price-container">
              <PriceEstimateDisplay priceDetails={priceDetails} />
            </div>
          </div>
        </div>
      </div>




      {/* Fixed Bottom Bar for "Next" Button */}
      <div className="fixed-bottom-bar">
        <div className="button-bar">
          {orderSubmittedBinary ? (
            <button className="blue-button" onClick={returnHome}>
              Return Home
            </button>
          ) : (
            <button
              className="blue-button"
              onClick={scheduleServiceBackend}
              disabled={isProcessing}
            >
              {isProcessing ? 'Ordering...' : 'Order'}
            </button>
          )}
        </div>
      </div>




    </div>
  );


}

export default SummaryScreen;
