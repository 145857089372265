// src/slices/desiredCleanersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const desiredCleanersSlice = createSlice({
  name: 'desiredCleaners',
  initialState,
  reducers: {
    setSoloCleaner: (state, action) => {
      return [action.payload]; // action.payload is now expected to be a single ID
    },
    setMultipleCleaners: (state, action) => {
      return [...action.payload]; // action.payload is an array of IDs
    },
    resetCleaners: () => {
      return []; // Resets the state to an empty array
    },
  },
});

export const { setSoloCleaner, setMultipleCleaners, resetCleaners } = desiredCleanersSlice.actions;
export default desiredCleanersSlice.reducer;
