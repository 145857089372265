// src/screens/admin/JobDetailPage.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Breadcrumbs from '../../components/admin/Breadcrumbs';
import CustomHeader from '../../components/admin/CustomHeader';
import DataGridCard from '../../components/admin/DataGridCard';
import GoogleMapComponent from '../../components/admin/GoogleMapComponent';
import FieldInputWithButton from '../../components/admin/FieldInputWithButton';
import Button from '../../components/admin/Button';

import Header from '../../components/Header'; // Import the Header component
import { apiBaseUrl } from '../../apiConfig';
import { getFirebaseToken } from '../../utils/firebaseTokenUtil';

import SectionHeader from '../../components/SectionHeader';
import ConfirmationDialog from '../../components/ConfirmationDialog';


function determineJobStatus(jobDetails) {
  if (!jobDetails) return "Status unknown";

  const { paymentIntentDetails, jobDetail } = jobDetails;
  const { jobCompleteBinary, cleanerClockStatus, jobDeletedBinary } = jobDetail;

  if (jobDeletedBinary && paymentIntentDetails.paymentIntentStatus === "not created") {
    return "Not started / cancelled";
  } else if (paymentIntentDetails.paymentIntentStatus === "succeeded" && jobCompleteBinary) {
    return "Complete - closed";
  } else if (paymentIntentDetails.paymentIntentStatus === "succeeded" && !jobCompleteBinary) {
    return "Complete - unclosed";
  } else if (!jobDeletedBinary && !jobCompleteBinary && paymentIntentDetails.paymentIntentStatus === "not created") {
    return "Payment authorization required";
  } else if (cleanerClockStatus !== "All clocked" && !jobDeletedBinary && !jobCompleteBinary) {
    return "Require cleaner clock data";
  } else if (cleanerClockStatus === "All clocked" && paymentIntentDetails.paymentIntentStatus !== "succeeded" && !jobDeletedBinary && !jobCompleteBinary) {
    return "Requires payment";
  } else {
    return "Status unknown";
  }
}


const isCaptureAmountValid = (captureAmount, amountAuthorized) => {
  // Parse float to handle inputs like "200.00"
  const captureValue = parseFloat(captureAmount);
  const authorizedValue = parseFloat(amountAuthorized);

  // Check if either value is NaN or if captureValue exceeds authorizedValue
  if (isNaN(captureValue) || isNaN(authorizedValue) || captureValue > authorizedValue) {
      return false;
  }
  return true;
};  


// Helper function to format amounts as currency
const formatCurrency = (amount) => {
  // Check if the amount is a valid number
  const parsedAmount = parseFloat(amount);
  if (isNaN(parsedAmount)) {
      return amount;  // Return whatever string is in place of the amount in `amount` if not a valid number
  }
  // Format the number as a currency
  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
  }).format(parsedAmount);
};


function formatTimeWithHours(time) {
  return time === "N/A" ? time : `${time} hrs`;
}



const JobDetailPage = () => {
  const { jobId } = useParams(); // Get jobId from URL parameters

  const [jobLocation, setJobLocation] = useState(null); 
  const [jobDetails, setJobDetails] = useState(null); 
  const [jobStatus, setJobStatus] = useState("Loading...");
  const [cleanersData, setCleanersData] = useState([]);

  const [authorizationAmount, setAuthorizationAmount] = useState('');
  const [isAuthorizeButtonDisabled, setIsAuthorizeButtonDisabled] = useState(true);

  const [captureAmount, setCaptureAmount] = useState('');
  const [isCaptureButtonDisabled, setIsCaptureButtonDisabled] = useState(true);

  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);

  const [isClosing, setIsClosing] = useState(false); 
  const [isDeleting, setIsDeleting] = useState(false); 

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);


  // Default breadcrumbs
  const defaultBreadcrumbs = [
    { name: 'Home', route: '/adminHome' },
    { name: 'Loading...', route: '/job-statuses' }, // Show "Loading..." initially
    { name: 'Job Details', route: '' },
  ];


  // Conditionally set breadcrumbs based on `jobDetails`
  const breadcrumbs = useMemo(() => {
    if (!jobDetails || !jobDetails.jobDetail) {
      return defaultBreadcrumbs;
    }

    if (jobDetails.jobDetail.jobDeletedBinary) {
      return [
        { name: 'Home', route: '/adminHome' },
        { name: 'Deleted Jobs', route: '/deleted-jobs' },
        { name: 'Job Details', route: '' },
      ];
    } else if (jobDetails.jobDetail.jobCompleteBinary) {
      return [
        { name: 'Home', route: '/adminHome' },
        { name: 'Completed Jobs', route: '/completed-jobs' },
        { name: 'Job Details', route: '' },
      ];
    } else {
      return [
        { name: 'Home', route: '/adminHome' },
        { name: 'Job Statuses', route: '/job-statuses' },
        { name: 'Job Details', route: '' },
      ];
    }
  }, [jobDetails]);


  // Define the fetchJobDetails function
  const fetchJobDetails = async () => {
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');

      // Make the API call with the ID token in the headers
      const response = await axios.get(`${apiBaseUrl}/admin/fetch-job/${jobId}`, {
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
      });

      // Update state with job details
      setJobDetails(response.data);
      setCleanersData(response.data.cleanerDetails);

      // Debugging logs
      console.log("response.data", response.data);
      console.log("response.data.cleanerDetails", response.data.cleanerDetails);

      // Update job location if latitude and longitude are available
      if (response.data.property && response.data.property.latitude && response.data.property.longitude) {
        setJobLocation({
          lat: response.data.property.latitude,
          lng: response.data.property.longitude,
        });
      }

      // Handle payment intent details and button states
      if (response.data.paymentIntentDetails) {
        setIsAuthorizeButtonDisabled(
          response.data.paymentIntentDetails.paymentIntentStatus !== "not created"
        );

        setIsCaptureButtonDisabled(
          response.data.paymentIntentDetails.paymentIntentStatus !== "requires_capture"
        );
      }
    } catch (error) {
      console.error('Error fetching job details:', error);
    }
  };


  // Use useEffect to call fetchJobDetails on mount
  useEffect(() => {
    fetchJobDetails();
  }, [jobId]); // Dependency array ensures it runs when jobId changes


  // Handle create payment intent
  const handleCreatePaymentIntent = async () => {
    if (!authorizationAmount) {
      alert("Please enter an authorization amount.");
      return;
    }

    setIsAuthorizing(true); // Set loading to true
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');

      // Make the API call with the ID token in the headers
      await axios.post(
        `${apiBaseUrl}/admin/create-payment-intent`,
        {
          amount: authorizationAmount,
          jobId: jobId,
        },
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        }
      );

      fetchJobDetails(); // Refresh job details to reflect the new payment intent
      alert(`Payment Intent Created`);
    } catch (error) {
      console.error('Failed to create payment intent:', error);
      alert('Failed to create payment intent: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsAuthorizing(false); // Reset loading state
    }
  };

  const handleCapturePayment = async () => {
    if (!captureAmount) {
      alert("Please enter a capture amount.");
      return;
    }
  
    const amountAuthorized = jobDetails.paymentIntentDetails.amountAuthorized.replace(/[^0-9.-]+/g, "");
    if (!isCaptureAmountValid(captureAmount, amountAuthorized)) {
      alert("Invalid capture amount. Please ensure the capture amount does not exceed the amount authorized.");
      return;
    }
  
    setIsCapturing(true); // Set loading to true
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');
  
      // Make the API call with the ID token in the headers
      await axios.post(
        `${apiBaseUrl}/admin/capture-payment`,
        {
          jobId,
          captureAmount,
        },
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        }
      );
  
      fetchJobDetails(); // Refresh job details to reflect the captured payment
  
      // Format and display success message
      const formattedAmount = formatCurrency(captureAmount);
      alert(`Payment Captured for ${formattedAmount}`);
    } catch (error) {
      console.error('Failed to capture payment:', error);
      alert('Failed to capture payment: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsCapturing(false); // Reset loading state
    }
  };



  const handleCloseJob = async () => {
    setIsClosing(true); // Start loading indicator
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');
  
      // Make the API call with the ID token in the headers
      const response = await axios.post(
        `${apiBaseUrl}/admin/close-job-and-update-data`,
        { jobId },
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        }
      );
  
      alert('Job closed successfully: ' + response.data.message);
  
      // Refresh job details to reflect new status
      fetchJobDetails();
    } catch (error) {
      console.error('Failed to close job:', error);
      alert('Failed to close job: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsClosing(false); // End loading indicator
    }
  };  


  // Helper functions to handle modal actions
  const handleConfirmDelete = () => {
    setShowDeleteConfirmation(false); // Close the dialog
    handleDeleteJob(); // Call the delete job function
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false); // Close the dialog
  };


  const handleDeleteJob = async () => {
    setIsDeleting(true); // Start loading indicator for deleting
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');
  
      // Make the API call with the ID token in the headers
      const response = await axios.post(
        `${apiBaseUrl}/remove-scheduled-job`,
        { jobId },
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        }
      );
  
      alert('Job deleted successfully: ' + response.data.message);
  
      // Refresh job details to reflect new status
      fetchJobDetails();
    } catch (error) {
      console.error('Failed to delete job:', error);
      alert('Failed to delete job: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsDeleting(false); // End loading indicator
    }
  };  


  useEffect(() => {
    if (jobDetails) {
        const status = determineJobStatus(jobDetails);
        setJobStatus(status); // Update the state with the new status
    }
  }, [jobDetails]); // This effect runs whenever jobDetails changes

  const handleAuthorizationInputChange = (e) => {
    setAuthorizationAmount(e.target.value);
  };

  const handleCaptureInputChange = (e) => {
    setCaptureAmount(e.target.value);
  };

  const statusData = jobDetails ? [
    { name: 'Job Status', data: jobStatus },
    { name: 'Clock Status', data: jobDetails.jobDetail.cleanerClockStatus },
    { name: 'Estimated Job Price', data: formatCurrency(jobDetails.jobDetail.priceEstimate) },
    { name: 'Actual Job Price', data: formatCurrency(jobDetails.jobDetail.actualJobPrice) },
  ] : [];

  const basicsData = jobDetails ? [
    { name: 'Service Date', data: jobDetails.jobDetail.serviceDate },
    { name: 'Number of Cleaners', data: jobDetails.jobDetail.numberOfCleaners.toString() },
    { name: 'Estimated Total Clean Hours', data: formatTimeWithHours(jobDetails.jobDetail.totalEstimatedCleanTime) },
    { name: 'Actual Total Clean Hours', data: formatTimeWithHours(jobDetails.jobDetail.totalClockedHours) },
  ] : [];

  const paymentCaptureData = jobDetails ? [
    { name: 'Payment Intent Status', data: jobDetails.paymentIntentDetails.paymentIntentStatus },
    { name: 'Amount Authorized', data: formatCurrency(jobDetails.paymentIntentDetails.amountAuthorized) },
    { name: 'Amount Captured', data: formatCurrency(jobDetails.paymentIntentDetails.amountCaptured) },
    { name: 'Authorization Date', data: jobDetails.paymentIntentDetails.createdUtc },
    { name: 'Authorization Exp. Date', data: jobDetails.paymentIntentDetails.expiresAtUtc }
  ] : [];

  const cardData = jobDetails ? [
    { name: 'Brand', data: jobDetails.paymentMethod.brand },
    { name: 'Expiration Date', data: `${jobDetails.paymentMethod.expMonth}/${jobDetails.paymentMethod.expYear}` },
    { name: 'Last Four', data: jobDetails.paymentMethod.last4 }
  ] : [];

  const stripeIdsData = jobDetails ? [
    { name: 'Stripe Customer ID', data: jobDetails.stripeDetails.customerId },
    { name: 'Stripe Payment Method', data: jobDetails.stripeDetails.paymentMethodId },
    { name: 'Stripe Payment Fingerprint', data: jobDetails.stripeDetails.fingerprint },
    { name: 'Stripe Payment Intent ID', data: jobDetails.stripeDetails.externalStripePaymentIntentId }
  ] : [];

  const renderCustomerData = (customerDetails) => [
    { name: 'Name', data: customerDetails.customerName, type: 'text' },
    { name: 'Phone number', data: customerDetails.phone, type: 'phone' },
    { name: 'Email', data: customerDetails.email, type: 'email' },
  ];
  
  const propertyDetailsData = jobDetails ? [
    { name: 'Address', data: jobDetails.property.formattedAddress || 'N/A' },
    { name: '# Bedrooms', data: jobDetails.property.numberBedrooms != null ? jobDetails.property.numberBedrooms.toString() : 'N/A' },
    { name: '# Bathrooms', data: jobDetails.property.numberBathrooms != null ? jobDetails.property.numberBathrooms.toString() : 'N/A' },
    { name: 'Total Square Feet', data: jobDetails.property.squareFeet != null ? jobDetails.property.squareFeet.toString() : 'N/A' },
    { name: '# Cats', data: jobDetails.property.numberCats != null ? jobDetails.property.numberCats.toString() : 0 },
    { name: '# Dogs', data: jobDetails.property.numberDogs != null ? jobDetails.property.numberDogs.toString() : 0 },
    { name: 'Property Access Notes', data: jobDetails.property.propertyAccessNotes || 'N/A' },
    { name: 'Property Pet Notes', data: jobDetails.property.propertyPetsNotes || 'N/A' }, // handle possible null or undefined
  ] : [];  

  const jobDetailsSectionData = jobDetails ? [
    { name: 'Job Status', data: jobStatus },
    { name: 'Service Date', data: jobDetails.jobDetail.serviceDate },
    { name: 'Cleaning Type', data: jobDetails.jobDetail.cleaningType },
    { name: '# Cleaners Assigned', data: jobDetails.jobDetail.numberOfCleaners.toString() },
    { name: 'Clock Status', data: jobDetails.jobDetail.cleanerClockStatus },
    { name: 'Est. Total Clean Hours', data: formatTimeWithHours(jobDetails.jobDetail.totalEstimatedCleanTime) },
    { name: 'Est. Job Time', data: formatTimeWithHours(jobDetails.jobDetail.estimatedCleanTime) },
    { name: 'Actual Total Clean Hours', data: formatTimeWithHours(jobDetails.jobDetail.totalClockedHours) },
    { name: 'Actual Job Time', data: formatTimeWithHours(jobDetails.jobDetail.totalJobTime) },
    { name: 'Estimated Job Price', data: formatCurrency(jobDetails.jobDetail.priceEstimate) },
    { name: 'Actual Job Price', data: formatCurrency(jobDetails.jobDetail.actualJobPrice) }
  ] : [];

  const renderCleanerData = (cleaner) => [
    { name: 'Phone #', data: cleaner.phone, type: 'phone' },
    { name: 'Email', data: cleaner.email, type: 'email' },
    // Add other fields as needed
  ];

  
  return (
    <div className="full-width-container">
      {/* Full-width Header Section */}
      <Header />

      {/* Deletion confirmation modal */}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          message="Are you sure you want to delete this job? This action cannot be undone."
          onConfirm={handleConfirmDelete} // Handle "Yes" option
          onCancel={handleCancelDelete}   // Handle "No" option
        />
      )}
  
      {/* Main content section */}
      <div className="content-container column">
        <Breadcrumbs crumbs={breadcrumbs} />
  
        <h1>
          Job Detail: {jobDetails && jobDetails.property ? `${jobDetails.property.address}, ${jobDetails.property.zipCode}` : 'Loading details...'}
        </h1>

        {/* Section Header for Job Status */}
        {jobDetails && jobDetails.jobDetail && (
          <>
            {jobDetails.jobDetail.jobDeletedBinary && (
              <SectionHeader
                text="DELETED"
                backgroundColor="black"
                fontColor="red"
                marginBottom={5}
                padding={5}
              />
            )}
            {!jobDetails.jobDetail.jobDeletedBinary && jobDetails.jobDetail.jobCompleteBinary && (
              <SectionHeader
                text="COMPLETE"
                backgroundColor="black"
                fontColor="#33cc33"
                marginBottom={5}
                padding={5}
              />
            )}
            {!jobDetails.jobDetail.jobDeletedBinary && !jobDetails.jobDetail.jobCompleteBinary && (
              <SectionHeader
                text="INCOMPLETE"
                backgroundColor="black"
                fontColor="white"
                marginBottom={5}
                padding={5}
              />
            )}
          </>
        )}
  
        {jobLocation ? (
          <GoogleMapComponent location={jobLocation} />
        ) : (
          <div>Loading map...</div>
        )}
  
        <br />
  
        <CustomHeader backgroundColor="#000099" textColor="white" text="Overview" />
  
        <div style={gridContainerStyle}>
          <DataGridCard headerText="Status" data={statusData} />
          <DataGridCard headerText="Basics" data={basicsData} />
  
          <div style={cardContainerStyle}>
            <CustomHeader backgroundColor="black" textColor="white" text="Actions" />
            <div style={buttonWrapperStyle}>
              <Button
                onClick={handleCloseJob}
                color="red"
                width="150px"
                isLoading={isClosing}
                disabled={jobStatus !== 'Complete - unclosed' || isClosing}
              >
                Close Job
              </Button>
            </div>

            <div style={buttonWrapperStyle}>
              <Button
                onClick={() => setShowDeleteConfirmation(true)} // Open the confirmation modal
                color="red"
                width="150px"
                isLoading={isDeleting} // Show loading state while deleting
                disabled={
                  isDeleting ||
                  (jobDetails?.jobDetail && (jobDetails.jobDetail.jobCompleteBinary || jobDetails.jobDetail.jobDeletedBinary))
                } // Prevent multiple clicks and disable if the job is complete or deleted
              >
                Delete Job
              </Button>
            </div>


          </div>
        </div>
  
        <br />
  
        <CustomHeader backgroundColor="#000099" textColor="white" text="Payment Information" />
  
        <FieldInputWithButton
          label="Enter Authorization Amount:"
          value={authorizationAmount}
          buttonText="Set Authorization Amount"
          onChange={handleAuthorizationInputChange}
          onSubmit={handleCreatePaymentIntent}
          buttonDisabled={isAuthorizeButtonDisabled || isAuthorizing}
          isLoading={isAuthorizing}
          labelPixelWidth="240px"
          buttonWidth="250px"
        />
  
        <FieldInputWithButton
          label="Enter Capture Amount:"
          value={captureAmount}
          buttonText="Capture Payment"
          onChange={handleCaptureInputChange}
          onSubmit={handleCapturePayment}
          buttonDisabled={isCaptureButtonDisabled || isCapturing}
          isLoading={isCapturing}
          labelPixelWidth="240px"
          buttonWidth="250px"
        />
  
        <div style={gridContainerStyle}>
          <DataGridCard headerText="Payment Capture" data={paymentCaptureData} />
          <DataGridCard headerText="Card" data={cardData} />
          <DataGridCard headerText="Stripe IDs" data={stripeIdsData} />
        </div>
  
        <br />
  
        <CustomHeader backgroundColor="#000099" textColor="white" text="Customer / Property / Job" />
  
        <div style={gridContainerStyle}>

          {jobDetails && jobDetails.customerDetails && (
            <DataGridCard 
              headerText="Customer" 
              data={renderCustomerData(jobDetails.customerDetails)}
            />
          )}

          <DataGridCard headerText="Property" data={propertyDetailsData.map(data => ({ name: data.name, data: data.data }))} />
          <DataGridCard headerText="Job" data={jobDetailsSectionData.map(data => ({ name: data.name, data: data.data }))} />
        </div>
  
        <br />
  
        <CustomHeader backgroundColor="#000099" textColor="white" text="Cleaners" />  
        <div style={gridContainerStyle}>
          {cleanersData.map((cleaner, index) => (
            <DataGridCard
              key={index}
              headerText={`${cleaner.lastName}, ${cleaner.firstName}`}
              data={renderCleanerData(cleaner)}
              imageSrc={cleaner.profilePhoto}
            />
          ))}
        </div>
  
        <br />

      </div>
    </div>
  );

};


// Inline styles for the grid layout
const gridContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '10px',
};

const cardContainerStyle = {
  border: '1px solid #ccc',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: '100%'
};

const buttonWrapperStyle = {
  padding: '10px', // Add padding if necessary
  flexGrow: 1, // Flex grow to take up all available space
  display: 'flex',
  justifyContent: 'center', // Center button horizontally
  alignItems: 'center', // Center button vertically
};



export default JobDetailPage;


