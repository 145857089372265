import { createSlice } from '@reduxjs/toolkit';

// Define the initial state with fields relevant to a customer user
const initialState = {
  id: null,
  accountCreationDate: null,
  firstName: null,
  lastName: null,
  role: 'customer', // Assuming the role is always 'customer' for this slice
  phone: null,
  phoneVerified: false,
  email: null,
  // You can add more fields specific to the customer user here
};

// Create the slice
const customerUserSlice = createSlice({
  name: 'customerUser',
  initialState,
  reducers: {
    // Action to update the customer user data
    updateCustomerUser: (state, action) => {
      const {
        id,
        accountCreationDate,
        firstName,
        lastName,
        role,
        phone,
        phoneVerified,
        email
        // Include other fields as needed
      } = action.payload;

      // Update state with the new values, only if they exist
      Object.keys(action.payload).forEach(key => {
        if (action.payload[key] !== undefined) {
          state[key] = action.payload[key];
        }
      });
    },
    // Action to reset the customer user state to its initial values
    resetCustomerUserState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

// Destructure and export the actions
export const { updateCustomerUser, resetCustomerUserState } = customerUserSlice.actions;

// Export the reducer as the default export
export default customerUserSlice.reducer;
