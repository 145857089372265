// src/components/UnselectedCleanerBlock.js

import React from 'react';
import './UnselectedCleanerBlock.css';

const UnselectedCleanerBlock = ({ onPress }) => {
  return (
    <div className="wrapper" onClick={onPress}>
      <div className="block" />
      <div className="labelContainer">
        <span className="labelText">unselected</span>
      </div>
    </div>
  );
};

export default UnselectedCleanerBlock;
