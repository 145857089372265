import React from 'react';
import './PriceEstimateDisplay.css'; // Ensure this CSS file is linked correctly

const PriceEstimateDisplay = ({ priceDetails }) => {
  if (!priceDetails || priceDetails.price <= 0) return null;

  return (
    <div className="price-estimate-container">
      <span className="price-title">{priceDetails.priceTitle}:</span>
      <span className="price-value">${priceDetails.price}</span>
    </div>
  );
};

export default PriceEstimateDisplay;
