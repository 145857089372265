import React, { useRef } from 'react';
import InputMask from 'react-input-mask';

function PhoneInputComponent({ phone, setPhone }) {
  const inputRef = useRef(null);

  return (
    <InputMask
      mask="(999) - 999 - 9999"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      placeholder="(123) - 456 - 7890"
      ref={inputRef} // Attach the ref here to avoid findDOMNode usage
    >
      {(inputProps) => <input {...inputProps} type="tel" className="customer-input" />}
    </InputMask>
  );
}

export default PhoneInputComponent;



// import React from 'react';
// import InputMask from 'react-input-mask';

// function PhoneInputComponent({ phone, setPhone }) {
//   return (
//     <InputMask
//       mask="(999) - 999 - 9999"
//       value={phone}
//       onChange={(e) => setPhone(e.target.value)} // Directly store the formatted phone number
//       placeholder="(123) - 456 - 7890"
//     >
//       {(inputProps) => <input {...inputProps} type="tel" className="customer-input" />}
//     </InputMask>
//   );
// }

// export default PhoneInputComponent;
