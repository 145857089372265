// src/thunks/taskSubscriptionManager.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { doc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { addOrUpdateTasks, removeTasks } from '../slices/subscribedTasksSlice';

// This map persists across calls to the taskSubscriptionManager
const unsubscribers = new Map();

export const taskSubscriptionManager = createAsyncThunk(
  'taskSubscriptionManager',
  async (tasksPayload, { dispatch }) => {
    if (tasksPayload.type === 'sync') {
      unsubscribers.forEach((unsubscribe, taskId) => {
        if (!tasksPayload.tasks.some(task => task.id === taskId)) {
          unsubscribe();
          unsubscribers.delete(taskId);
          dispatch(removeTasks([taskId]));
        }
      });

      tasksPayload.tasks.forEach(task => {
        if (!unsubscribers.has(task.id)) {
          try {
            const taskRef = doc(firestore, "tasks", String(task.id));
            const unsubscribe = onSnapshot(taskRef, (docSnapshot) => {
              dispatch(addOrUpdateTasks({ [task.id]: docSnapshot.data() || null }));
            });
            unsubscribers.set(task.id, unsubscribe);
          } catch (error) {
            console.error("Failed to subscribe to task:", task.id, error);
          }
        }
      });
    } else if (tasksPayload.type === 'add') {
      tasksPayload.tasks.forEach(task => {
        if (!unsubscribers.has(task.id)) {
          try {
            const taskRef = doc(firestore, "tasks", String(task.id));
            const unsubscribe = onSnapshot(taskRef, (docSnapshot) => {
              dispatch(addOrUpdateTasks({ [task.id]: docSnapshot.data() || null }));
            });
            unsubscribers.set(task.id, unsubscribe);
          } catch (error) {
            console.error("Failed to add new task subscription:", task.id, error);
          }
        }
      });
    } else if (tasksPayload.type === 'clear') {
      unsubscribers.forEach((unsubscribe, taskId) => {
        unsubscribe();
        unsubscribers.delete(taskId);
        dispatch(removeTasks([taskId]));
      });
    }
  }
);
