// src/components/GoogleMapComponent.js
import React from 'react';
import { GoogleMap, useJsApiLoader, MarkerF as Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const GoogleMapComponent = ({ location }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAJxipcsHRfb6B6ESW-57cIkUJjUlu7v2Q', // Replace with your API key
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // Check if the location is valid
  if (!location || typeof location.latitude !== 'number' || typeof location.longitude !== 'number') {
    console.error('Invalid location data:', location);
    return <div>Invalid location data</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: location.latitude, lng: location.longitude }}
      zoom={18}
    >
      <Marker position={{ lat: location.latitude, lng: location.longitude }} />
    </GoogleMap>
  );
};

export default GoogleMapComponent;
