import React from 'react';
import Button from './Button'; // Adjust the import path as necessary
import styles from './FieldInputWithButton.module.css'; // Ensure this path is correct

const FieldInputWithButton = ({
  label,
  value,
  onChange,
  onSubmit,
  buttonText = 'Submit', // Default button text
  buttonDisabled = false, // Default is now set to false for demonstration
  isLoading = false, // Prop to control the loading state of the button
  labelPixelWidth = 'auto', // Default width of label
  buttonWidth = '150px' // Default minimum width of the button
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.labelContainer} style={{ width: labelPixelWidth }}>
        <label className={styles.label}>
          {label}
        </label>
      </div>
      <input 
        type="text"
        className={styles.input}
        value={value}
        onChange={onChange}
        disabled={buttonDisabled || isLoading} // Disable input based on buttonDisabled or isLoading prop
      />
      <Button 
        onClick={onSubmit} 
        color="green"
        disabled={buttonDisabled || isLoading} // Pass the disabled state to the Button
        isLoading={isLoading} // Pass the isLoading state to the Button
        width={buttonWidth} // Pass the width to the Button component
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default FieldInputWithButton;
