// navigationSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    nextScreen: null
  },
  reducers: {
    setNextScreen: (state, action) => {
      state.nextScreen = action.payload;
    },
    clearNextScreen: (state) => {
      state.nextScreen = null;
    }
  }
});

export const { setNextScreen, clearNextScreen } = navigationSlice.actions;

export default navigationSlice.reducer;
