// src/components/ScheduledJob.js
import React from 'react';
import { useSelector } from 'react-redux';
import ImageRow from './ImageRow';
import './ScheduledJob.css';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const ScheduledJob = ({ data, scheduleSlots, imageItems, onJobPress }) => {
  const properties = useSelector((state) => state.properties);
  const { cleaningType, scheduleSlotId, propertyId } = data;

  const property = properties.find((property) => property.id === propertyId);
  const scheduleSlot = scheduleSlots.find((slot) => slot.id === scheduleSlotId);

  const formattedDate = scheduleSlot ? formatDate(scheduleSlot.date) : '';
  const address = property ? property.address : '';
  const propertyName = property ? property.propertyName : '';

  let cleaningTypeText;
  switch (cleaningType) {
    case 'deep':
      cleaningTypeText = 'Deep Clean';
      break;
    case 'standard':
      cleaningTypeText = 'Standard Clean';
      break;
    case 'moveout':
      cleaningTypeText = 'Move Out Clean';
      break;
    default:
      cleaningTypeText = '';
  }

  return (
    <div className="scheduled-job-container" onClick={() => onJobPress && onJobPress(data.id)}>
      <div className="scheduled-job-property-name">
        <span className="scheduled-job-property-name-text">{propertyName}</span>
      </div>

      {property && property.propertyType === 'apartment' && (
        <>
          <div className="scheduled-job-address-row">
            <span className="scheduled-job-address-text">Apt. Name: {property.apartmentName}</span>
          </div>
          <div className="scheduled-job-address-row">
            <span className="scheduled-job-address-text">Apt. Unit: {property.apartmentUnit}</span>
          </div>
        </>
      )}

      {imageItems && imageItems.length > 0 && (
        <ImageRow items={imageItems} isNavigationEnabled={false} targetScreen="ToDoListScreen" placeholderImageType="checklist" />
      )}

      <div className="scheduled-job-address-row">
        <span className="scheduled-job-address-text">{address}</span>
      </div>
      <div className="scheduled-job-cleaning-type-row">
        <span className="scheduled-job-cleaning-type-text">{cleaningTypeText}</span>
      </div>
      <div className="scheduled-job-date-row">
        <span className="scheduled-job-date-text">{formattedDate}</span>
      </div>
    </div>
  );
};

export default ScheduledJob;
