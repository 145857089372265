// src/utils/taskUtils.js


// Function specific for the Home Screen
export const constructHomeScreenItemsFromTasks = (tasks, propertyId, taskObjects) => {
    const itemsForImageRow = tasks
      .filter(task => task.propertyId === propertyId)
      .map(task => {
        const taskData = taskObjects[task.id];
        if (taskData && taskData.beforeThumbURL) {
          return { image: taskData.beforeThumbURL };
        } else if (task.taskCustomerText) {
          return { text: task.taskCustomerText };
        } else {
          return null;
        }
      })
      .filter(item => item !== null);
  
    return itemsForImageRow;
  };
  
  
// General-purpose function for constructing items from tasks
export const constructItemsFromTasks = (tasks, subscribedTaskImages) => {
  return tasks.map(task => {
    const taskData = subscribedTaskImages[task.id];
    if (taskData && taskData.beforeThumbURL) {
      return { image: taskData.beforeThumbURL };
    } else if (task.taskCustomerText) {
      return { text: task.taskCustomerText };
    } else {
      return null;
    }
  }).filter(item => item !== null); // Remove null entries
};
  

// Function to generate items for ImageRow from subscribedCleanerPhotos
export const generateImageRowItems = (subscribedCleanerPhotos) => {
  let items = [];

  subscribedCleanerPhotos.forEach(photo => {
    if (photo.beforeThumbURL) {
      items.push({ image: photo.beforeThumbURL });
    }
    if (photo.afterThumbURL) {
      items.push({ image: photo.afterThumbURL });
    }
    if (!photo.beforeThumbURL && !photo.afterThumbURL && photo.cleanerNote) {
      items.push({ text: photo.cleanerNote });
    }
  });

  return items;
};