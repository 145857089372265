import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  id: null,
  accountCreationDate: null,
  firstName: null,
  lastName: null,
  role: null,
  phone: null,
  phoneVerified: false,
  email: null
};

// Helper function to remove a cookie
function removeCookie(name) {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
}

// Async action to handle logout and remove token from cookies
const logoutUser = createAsyncThunk('user/logoutUser', async (_, { dispatch }) => {
  removeCookie('firebaseIdToken'); // Remove token cookie
  dispatch(resetUserState());
});

// Create user slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      // Directly update state with payload data
      Object.keys(action.payload).forEach(key => {
        if (action.payload[key] !== undefined) {
          state[key] = action.payload[key];
        }
      });
    },
    resetUserState: (state) => {
      Object.assign(state, initialState); // Reset state to initial values
    },
  },
});

export const { updateUser, resetUserState } = userSlice.actions;
export { logoutUser };
export default userSlice.reducer;





