import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import styles from './HoverTable.module.css';

const HoverTable = ({
  headers,
  data,
  onRowClick,
  fontSize = '1rem',
  headerFontSize = '1.1rem',
}) => {

  // Now includes rowIndex and cellIndex for key prop
  const renderTextCell = (content, rowIndex, cellIndex, fontSize) => (
    <TableCell
      key={`row-${rowIndex}-cell-${cellIndex}`} // Unique key for each TableCell
      sx={{ padding: '10px', textAlign: 'left', fontSize: fontSize }}
    >
      {content}
    </TableCell>
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={`header-${index}`} // Unique key for header cells
                sx={{ fontSize: headerFontSize, fontWeight: 'bold', backgroundColor: '#ffffff', borderBottom: '3px solid #000000', padding: '10px', textAlign: 'left' }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData, rowIndex) => (
            <TableRow
              key={`row-${rowIndex}`} // Unique key for each TableRow
              sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f7f7f7' }, '&:nth-of-type(even)': { backgroundColor: '#ffffff' } }}
              className={styles.hoverableRow}
              onClick={() => onRowClick(rowIndex)}
            >
              {rowData.map((cellData, cellIndex) => (
                // Pass the row and cell index to the renderTextCell function
                renderTextCell(cellData.content, rowIndex, cellIndex, fontSize)
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HoverTable;
