// src/slices/recurringSoloDayCleanersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const recurringSoloDayCleanersSlice = createSlice({
  name: 'recurringSoloDayCleaners',
  initialState,
  reducers: {
    setRecurringSoloDayCleaners: (state, action) => {
      return action.payload;
    },
    resetRecurringSoloDayCleaners: () => {
      return [];
    },
  },
});

export const { setRecurringSoloDayCleaners, resetRecurringSoloDayCleaners } = recurringSoloDayCleanersSlice.actions;
export default recurringSoloDayCleanersSlice.reducer;
