// src/components/SectionHeader.js

import React from 'react';
import './SectionHeader.css'; // Link to the CSS file for styling

const SectionHeader = ({
  text,
  number,
  onInfoPress,
  backgroundColor = '#FFFFFF',
  fontColor = '#000000',
  fontSize = 'normal',
  textAlign = 'left',
  width,
  height,
  marginTop = 0,
  marginBottom = 0,
  padding = 0, 
  style = {},
  ...props
}) => {
  const infoIcon = '/path/to/info-icon.png'; 

  return (
    <div
      className={`section-header-container ${textAlign}`}
      style={{
        backgroundColor,
        color: fontColor,
        width,
        height,
        marginTop,
        marginBottom,
        padding, // Set the padding from the prop
        ...style
      }}
      {...props}
    >
      <span className={`header-text ${fontSize}`}>
        {text}
        {number !== undefined && ` (${number})`}
      </span>
      {onInfoPress && (
        <button onClick={onInfoPress} className="info-button">
          <img src={infoIcon} alt="Info" className="info-icon" />
        </button>
      )}
    </div>
  );
};

export default SectionHeader;
