import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.css'; // Make sure this is the correct path to your CSS module

const Breadcrumbs = ({ crumbs }) => {
  const isLast = (index) => index === crumbs.length - 1;

  return (
    <div className={styles.breadcrumb}>
      {crumbs.map((crumb, index) => (
        <React.Fragment key={crumb.name}>
          {!isLast(index) ? (
            <>
              <Link to={crumb.route} className={styles.crumbLink}>
                {crumb.name}
              </Link>
              <span>/ </span> {/* Add space after the slash */}
            </>
          ) : (
            <span className={styles.crumbText}>{crumb.name}</span> /* Add class for styling the current page crumb */
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
