// src/screens/PaymentScreen.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { setCardInformation, resetCardInformation } from '../slices/selectedCardInformationSlice';
import { getFirebaseToken } from '../utils/firebaseTokenUtil';

import Header from '../components/Header';
import CreditCardType from '../components/CreditCardType';
import ErrorBox from '../components/ErrorBox';

import './PaymentScreen.css'; // Import custom CSS for styling
import { apiBaseUrl } from '../apiConfig'; // Import the base URL



function PaymentScreen() {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [cardDetails, setCardDetails] = useState(null);
  const [savedCards, setSavedCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [defaultPaymentId, setDefaultPaymentId] = useState(null);
  const [billingAddress, setBillingAddress] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);

  const user = useSelector((state) => state.user);
  const adminTargetUser = useSelector((state) => state.adminTargetUser);


  useEffect(() => {
    if (!stripe || !elements) {
      console.warn("Stripe has not yet loaded");
      // Consider disabling buttons until stripe is loaded
    }
  }, [stripe, elements]);
  

  
  // // Function to fetch saved cards from the backend
  // const fetchSavedCards = useCallback(async () => {
  //   setLoadingText("Loading...");
  //   setIsLoading(true);

  //   const firebaseIdToken = await getFirebaseToken();
  //   if (!firebaseIdToken) {
  //     window.alert('No Firebase ID token found');
  //     setIsLoading(false);
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(
  //       `${apiBaseUrl}/payments/retrieve-saved-cards`,
  //       { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
  //     );

  //     console.log("response", response.data);
  //     dispatch(resetCardInformation());
  //     setSavedCards(response.data.cards);
  //     setDefaultPaymentId(response.data.defaultPaymentId);
  //   } catch (error) {
  //     window.alert("Failed to fetch saved cards.");
  //   }
  //   setIsLoading(false);
  // }, [dispatch]);
  

  // Function to fetch saved cards from the backend
  const fetchSavedCards = useCallback(async () => {
    setLoadingText("Loading...");
    setIsLoading(true);

    const firebaseIdToken = await getFirebaseToken();
    if (!firebaseIdToken) {
      window.alert('No Firebase ID token found');
      setIsLoading(false);
      return;
    }

    try {
      // Prepare query parameters
      const params = {};
      if (user.role === 'owner' && adminTargetUser.id) {
        params.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }

      // Make the API call
      const response = await axios.get(
        `${apiBaseUrl}/payments/retrieve-saved-cards`,
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
          params, // Include the query parameters
        }
      );

      console.log("response", response.data);
      dispatch(resetCardInformation());
      setSavedCards(response.data.cards);
      setDefaultPaymentId(response.data.defaultPaymentId);
    } catch (error) {
      window.alert("Failed to fetch saved cards.");
      console.error("Error fetching saved cards:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);



  // useEffect hook to load saved cards on component mount
  useEffect(() => {
    fetchSavedCards();
  }, [fetchSavedCards]);


  const handleBillingAddressChange = (e) => {
    setBillingAddress(e.target.value);
  };


  const handleBillingZipChange = (e) => {
    const filteredZip = e.target.value.replace(/\D/g, '');
    setBillingZip(filteredZip);
  };


  const validateAddressAndZip = () => {
    const errors = [];
    if (billingAddress.length < 5 || billingAddress.length > 50) {
      errors.push("Enter a billing street address.");
    }
    if (!/^\d{5}$/.test(billingZip)) {
      errors.push("Enter a valid US zip code.");
    }
    return errors;
  };



  // const handlePayPress = async () => {
  //   const errors = validateAddressAndZip();
  //   if (errors.length > 0) {
  //     setValidationErrors(errors);
  //     console.log("Validation errors:", errors);
  //     return;
  //   }
  
  //   setValidationErrors([]);
  //   setIsLoading(true);
  //   console.log("Initiating payment process...");
  
  //   try {

  //     const billingDetails = {
  //       email: user.email,
  //       phone: user.phone,
  //       address: {
  //         line1: billingAddress,
  //         postal_code: billingZip, // Corrected to match Stripe's expected format
  //         country: 'US',
  //       },
  //     };
      
  
  //     console.log("Billing details prepared:", billingDetails);
  
  //     const firebaseIdToken = await getFirebaseToken();
  //     if (!firebaseIdToken) {
  //       console.log("Firebase ID token not found.");
  //       window.alert("Session Timeout, please log in again.");
  //       setIsLoading(false);
  //       return;
  //     }
  
  //     console.log("Firebase ID token obtained.");
  
  //     const response = await axios.post(
  //       `${apiBaseUrl}/payments/create-setup-intent`,
  //       {},
  //       { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
  //     );
  
  //     const { clientSecret } = response.data;
  //     if (!clientSecret) {
  //       console.log("No client secret received from the backend.");
  //       window.alert("Setup error, please try again.");
  //       setIsLoading(false);
  //       return;
  //     }
  
  //     console.log("Client secret received:", clientSecret);
  
  //     // Use CardElement directly without ref
  //     const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: elements.getElement(CardElement),
  //       billing_details: billingDetails,
  //     });
  
  //     if (pmError) {
  //       console.error("Error creating payment method:", pmError);
  //       window.alert(`Error creating payment method: ${pmError.message}`);
  //       setIsLoading(false);
  //       return;
  //     }
  
  //     console.log("PaymentMethod created:", paymentMethod);
  
  //     // Confirm the SetupIntent with the paymentMethod ID
  //     const { setupIntent, error } = await stripe.confirmSetupIntent(clientSecret, {
  //       payment_method: paymentMethod.id,
  //     });
  
  //     if (error) {
  //       console.error("Stripe error during confirmSetupIntent:", error);
  //       if (error.type === 'card_error' || error.type === 'validation_error') {
  //         window.alert(`Error with card: ${error.message}`);
  //       } else {
  //         window.alert("An unexpected error occurred during card confirmation.");
  //       }
  //     } else if (setupIntent.status === 'succeeded') {
  //       console.log("SetupIntent succeeded:", setupIntent);
  //       await fetchSavedCards();
  //       window.alert("Card added successfully.");
  //     } else {
  //       console.log("SetupIntent status:", setupIntent.status);
  //       window.alert("Setup incomplete. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error during payment process:", error);
  //     window.alert("An error occurred, please try again.");
  //   } finally {
  //     setIsLoading(false);
  //     console.log("Payment process completed.");
  //   }
  // };



  const handlePayPress = async () => {
    const errors = validateAddressAndZip();
    if (errors.length > 0) {
      setValidationErrors(errors);
      console.log("Validation errors:", errors);
      return;
    }
  
    setValidationErrors([]);
    setIsLoading(true);
    console.log("Initiating payment process...");
  
    try {
      // Determine the billing details source
      const billingDetails = {
        email: user.role === 'owner' && adminTargetUser.id ? adminTargetUser.email : user.email,
        phone: user.role === 'owner' && adminTargetUser.id ? adminTargetUser.phone : user.phone,
        address: {
          line1: billingAddress,
          postal_code: billingZip, // Corrected to match Stripe's expected format
          country: 'US',
        },
      };
  
      console.log("Billing details prepared:", billingDetails);
  
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) {
        console.log("Firebase ID token not found.");
        window.alert("Session Timeout, please log in again.");
        setIsLoading(false);
        return;
      }
  
      console.log("Firebase ID token obtained.");
  
      // Prepare the request body
      const requestBody = {};
      if (user.role === 'owner' && adminTargetUser.id) {
        requestBody.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }
  
      const response = await axios.post(
        `${apiBaseUrl}/payments/create-setup-intent`,
        requestBody, // Pass the request body
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );
  
      const { clientSecret } = response.data;
      if (!clientSecret) {
        console.log("No client secret received from the backend.");
        window.alert("Setup error, please try again.");
        setIsLoading(false);
        return;
      }
  
      console.log("Client secret received:", clientSecret);
  
      // Use CardElement directly without ref
      const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      });
  
      if (pmError) {
        console.error("Error creating payment method:", pmError);
        window.alert(`Error creating payment method: ${pmError.message}`);
        setIsLoading(false);
        return;
      }
  
      console.log("PaymentMethod created:", paymentMethod);
  
      // Confirm the SetupIntent with the paymentMethod ID
      const { setupIntent, error } = await stripe.confirmSetupIntent(clientSecret, {
        payment_method: paymentMethod.id,
      });
  
      if (error) {
        console.error("Stripe error during confirmSetupIntent:", error);
        if (error.type === 'card_error' || error.type === 'validation_error') {
          window.alert(`Error with card: ${error.message}`);
        } else {
          window.alert("An unexpected error occurred during card confirmation.");
        }
      } else if (setupIntent.status === 'succeeded') {
        console.log("SetupIntent succeeded:", setupIntent);
        await fetchSavedCards();
        window.alert("Card added successfully.");
      } else {
        console.log("SetupIntent status:", setupIntent.status);
        window.alert("Setup incomplete. Please try again.");
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      window.alert("An error occurred, please try again.");
    } finally {
      setIsLoading(false);
      console.log("Payment process completed.");
    }
  };
  


  // Add this function inside PaymentScreen
  const selectDefaultCard = () => {
    if (savedCards.length === 0) {
      return false;
    }

    const defaultCard = savedCards.find(card => card.localId === defaultPaymentId);

    if (defaultCard) {
      const { paymentMethodId, ...cardInfo } = defaultCard; // Destructure to remove paymentMethodId
      dispatch(setCardInformation(cardInfo));
      return true;
    }

    return false;
  };




  const handleNextPress = () => {
    if (selectDefaultCard()) {
      navigate('/summary'); // Use navigate for React Router navigation
    } else {
      setValidationErrors(["No default payment method set. Please select or add a card."]);
    }
  };




  return (
    <div className="full-width-container with-padding-bottom">
      <Header />
      <div className="content-container column">

        <div className='form-container'>
            <h1>Payment Information</h1>
        </div>
        
        {/* Styled Container for Card Entry */}
        <div className="card-entry-container">
          {/* Powered by Image */}
          <img
            src="/images/static_ui/cards/powered.png"
            alt="Powered by"
            className="powered-image"
          />
  
          <CardElement
            className="card-element"
            onChange={(e) => setCardDetails(e.complete ? e : null)}
          />
  
          <input
            type="text"
            className="input-field"
            placeholder="Billing Address"
            value={billingAddress}
            onChange={handleBillingAddressChange}
          />
  
          <input
            type="text"
            className="input-field"
            placeholder="Billing Zip Code"
            value={billingZip}
            onChange={handleBillingZipChange}
          />
  
          {isLoading && <p className="loading-text">{loadingText}</p>}
  
          <button className="submit-button" onClick={handlePayPress} disabled={isLoading || !cardDetails}>
            Add Card
          </button>
  
          {validationErrors.length > 0 && (
            <ul className="error-list">
              {validationErrors.map((error, index) => (
                <li key={index} className="error-item">{error}</li>
              ))}
            </ul>
          )}
  
          {/* Render saved cards */}
          {savedCards.map((savedCard) => (
            <CreditCardType
              key={savedCard.localId}
              creditCardType={savedCard.brand}
              lastFour={savedCard.last4}
              expMonth={savedCard.expMonth}
              expYear={savedCard.expYear}
              selected={savedCard.localId === defaultPaymentId}
              onCardAction={() => setDefaultPaymentId(savedCard.localId)}
            />
          ))}
  
          {/* Render validation errors */}
          <ErrorBox errorTexts={validationErrors} />
        </div>
      </div>



      {/* Fixed Bottom Bar for "Next" Button */}
      <div className="fixed-bottom-bar">
        <div className="button-bar">
          <button
            className="blue-button"
            onClick={handleNextPress}
            disabled={!defaultPaymentId} // Button is disabled if there is no default payment method
          >
            Next
          </button>
        </div>
      </div>





    </div>
  );
  



}

export default PaymentScreen;




// // src/screens/PaymentScreen.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import Header from '../components/Header';

// function PaymentScreen() {
//   const navigate = useNavigate();

//   const handleNavigateToSummary = () => {
//     navigate('/order-summary');
//   };

//   return (
//     <div className="full-width-container with-padding-bottom">
//       <Header />
//       <div className="content-container">
//         <h1>Payment Screen</h1>
//         <p>This is a simplified version of the Payment Screen for troubleshooting.</p>
//         {/* Navigation button to go to the Order Summary screen */}
//         <button onClick={handleNavigateToSummary} className="blue-button">
//           Go to Order Summary
//         </button>
//       </div>
//     </div>
//   );
// }

// export default PaymentScreen;
