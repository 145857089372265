// src/utils/firebaseTokenUtil.js
import Cookies from 'js-cookie'; // Import js-cookie for cookie management
import { auth } from '../firebaseConfig';

export const getFirebaseToken = async () => {
  try {
    if (auth.currentUser) {
      const tokenResult = await auth.currentUser.getIdTokenResult();
      const currentTimeInSeconds = Date.now() / 1000;
      const timeUntilExpiration = tokenResult.claims.exp - currentTimeInSeconds;

      if (timeUntilExpiration < 300) { // Less than 5 minutes remaining
        const refreshedToken = await auth.currentUser.getIdToken(true);
        Cookies.set('firebaseIdToken', refreshedToken, { expires: 1 }); // Store in cookie for 1 day
        return refreshedToken;
      } else {
        return tokenResult.token;
      }
    } else {
      const storedToken = Cookies.get('firebaseIdToken');
      if (storedToken) {
        return storedToken;
      }
    }
  } catch (error) {
    console.error('Error handling Firebase token:', error);
  }
  return null;
};
