import React from 'react';
import './NumberIncrementor.css';

const NumberIncrementor = ({ 
  value, 
  onIncrement, 
  onDecrement, 
  alignment = 'center', 
  marginLeft = '0px', 
  disabled = false // Default to false
}) => {
  const displayValue = value === null ? '0' : value.toString();

  const containerStyles = {
    justifyContent: alignment,
    marginLeft: marginLeft,
  };

  return (
    <div className="number-incrementor" style={containerStyles}>
      <button
        className={`incrementor-button ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? undefined : onDecrement}
        disabled={disabled}
      >
        -
      </button>
      <span className="incrementor-value">{displayValue}</span>
      <button
        className={`incrementor-button ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? undefined : onIncrement}
        disabled={disabled}
      >
        +
      </button>
    </div>
  );
};

export default NumberIncrementor;


// import React from 'react';
// import './NumberIncrementor.css';

// const NumberIncrementor = ({ value, onIncrement, onDecrement, alignment = 'center', marginLeft = '0px' }) => {
//   const displayValue = value === null ? '0' : value.toString();

//   const containerStyles = {
//     justifyContent: alignment,
//     marginLeft: marginLeft,
//   };

//   return (
//     <div className="number-incrementor" style={containerStyles}>
//       <button className="incrementor-button" onClick={onDecrement}>
//         -
//       </button>
//       <span className="incrementor-value">{displayValue}</span>
//       <button className="incrementor-button" onClick={onIncrement}>
//         +
//       </button>
//     </div>
//   );
// };

// export default NumberIncrementor;



