// src/slices/serviceFrequencySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const serviceFrequencySlice = createSlice({
  name: 'serviceFrequency',
  initialState,
  reducers: {
    setFrequency: (state, action) => {
      return action.payload;
    },
    resetFrequency: () => {
      return '';
    },
  },
});

export const { setFrequency, resetFrequency } = serviceFrequencySlice.actions;
export default serviceFrequencySlice.reducer;
