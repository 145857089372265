// src/slices/desiredDateSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const desiredDateSlice = createSlice({
  name: 'desiredDate',
  initialState,
  reducers: {
    setDate: (state, action) => {
      return action.payload;
    },
    resetDate: () => {
      return null;
    },
  },
});

export const { setDate, resetDate } = desiredDateSlice.actions;
export default desiredDateSlice.reducer;
