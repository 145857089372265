import React from 'react';
import PropTypes from 'prop-types';
import './CreditCardType.css';

const CreditCardType = ({ creditCardType, lastFour, expMonth, expYear, onCardAction, selected, isClickable = true  }) => {
  let cardImage;

  if (!creditCardType) {
    cardImage = '/images/static_ui/cards/generic.png';
  } else {
    switch (creditCardType.toLowerCase()) {
      case 'amex':
        cardImage = '/images/static_ui/cards/amex.png';
        break;
      case 'discover':
        cardImage = '/images/static_ui/cards/discover.png';
        break;
      case 'mastercard':
        cardImage = '/images/static_ui/cards/master.png';
        break;
      case 'visa':
        cardImage = '/images/static_ui/cards/visa.png';
        break;
      default:
        cardImage = '/images/static_ui/cards/generic.png';
        break;
    }
  }

  return (
    <div
      className={`credit-card-container ${selected ? 'selected' : ''} ${isClickable ? 'clickable' : ''}`}
      onClick={isClickable ? onCardAction : undefined}
    >
      <div className="credit-card-image-container">
        <img src={cardImage} alt="Credit Card" className="credit-card-image" />
      </div>
      <div className="credit-card-details">
        <span className="credit-card-text">
          {`**${lastFour}${expMonth && expYear ? `, expires ${expMonth}/${expYear}` : ''}`}
        </span>
      </div>
    </div>
  );
};

CreditCardType.propTypes = {
  creditCardType: PropTypes.string,
  lastFour: PropTypes.string.isRequired,
  expMonth: PropTypes.string,
  expYear: PropTypes.string,
  onCardAction: PropTypes.func,
  selected: PropTypes.bool,
  isClickable: PropTypes.bool,
};

export default CreditCardType;
