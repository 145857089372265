// src/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { setUser, clearUser } from './slices/authSlice';
import { updateUser } from './slices/userSlice';
import { auth } from './firebaseConfig';
import { getFirebaseToken } from './utils/firebaseTokenUtil';
import axios from 'axios';
import { apiBaseUrl } from './apiConfig';

import MarketingScreen from './screens/MarketingScreen';

import HomeScreen from './screens/HomeScreen';

import ServiceHome from './screens/ServiceHome';
import DefineJobScreen from './screens/DefineJobScreen';
import PropertyScreen from './screens/PropertyScreen';
import ToDoListScreen from './screens/ToDoListScreen';
import SchedulingScreen from './screens/SchedulingScreen';
import PaymentScreen from './screens/PaymentScreen';
import SummaryScreen from './screens/SummaryScreen';


import CompleteSignupScreen from './screens/CompleteSignupScreen';

import AdminLoginScreen from './screens/admin/AdminLoginScreen';
import AdminHome from './screens/admin/AdminHome';
import JobStatuses from './screens/admin/JobStatuses'; 
import JobDetailPage from './screens/admin/JobDetailPage';
import DeletedJobs from './screens/admin/DeletedJobs';
import CompletedJobs from './screens/admin/CompletedJobs';

import CustomerLookup from './screens/admin/CustomerLookup';

import RecurringJobDetailPage from './screens/admin/RecurringJobDetailPage';
import RecurringJobsStatus from './screens/admin/RecurringJobsStatus';













import './styles.css';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);


  // Function to handle Firebase sign-in
  const handleFirebaseSignIn = async () => {
    try {
      const firebaseIdToken = await getFirebaseToken(); // Fetch the Firebase ID token
      const phoneNumber = auth.currentUser?.phoneNumber || null; // Get the phone number from the current Firebase user

      if (firebaseIdToken) {
        // Call the new /firebase-sign-in route
        const response = await axios.post(`${apiBaseUrl}/firebase-sign-in`, {
          idToken: firebaseIdToken,
          phoneNumber: phoneNumber,
        });

        if (response.status === 200 && response.data) {
          dispatch(updateUser(response.data)); // Update the user state with the received data
        }
      }
    } catch (error) {
      console.error('Error during Firebase sign-in:', error);
      throw error; // Re-throw the error to handle it in the caller
    }
  };

  // Initialize auth state on app startup
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          await handleFirebaseSignIn(); // Use the new /firebase-sign-in route
          dispatch(setUser()); // Only mark as logged in after signing in
        } catch (error) {
          console.error('Error handling Firebase sign-in:', error);
        }
      } else {
        dispatch(clearUser());
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);  


  const needsSignupCompletion = isLoggedIn && (!user.firstName?.trim() || !user.lastName?.trim());
  const isOwner = isLoggedIn && user.role === 'owner';


  // // useEffect for login redirection logic
  // useEffect(() => {
  //   if (isLoggedIn) {
  //     if (needsSignupCompletion) {
  //       navigate("/complete-signup");
  //     } else {
  //       navigate("/servicehome");
  //     }
  //   }
  // }, [isLoggedIn, needsSignupCompletion]);



  // useEffect for login redirection logic
  useEffect(() => {
    if (isLoggedIn) {
      if (needsSignupCompletion) {
        navigate("/complete-signup"); // Redirect to complete signup if user needs to complete signup
      } else if (isOwner) {
        navigate("/adminHome"); // Redirect owners to the admin home
      } else {
        navigate("/servicehome"); // Redirect other users (e.g., customers) to the service home
      }
    }
  }, [isLoggedIn, needsSignupCompletion, isOwner]);



  if (isLoading) {
    return <div>Loading...</div>;
  }
  

  return (
    <Routes>
      {/* Unrestricted access to the home route */}
      <Route path="/" element={<HomeScreen />} />   

      <Route path="/servicehome" element={isLoggedIn ? <ServiceHome /> : <Navigate to="/" />} />
      <Route path="/definejob" element={isLoggedIn ? <DefineJobScreen /> : <Navigate to="/" />} />
      <Route path="/property" element={isLoggedIn ? <PropertyScreen /> : <Navigate to="/" />} />
      <Route path="/todo-list" element={isLoggedIn ? <ToDoListScreen /> : <Navigate to="/" />} />
      <Route path="/scheduling" element={isLoggedIn ? <SchedulingScreen /> : <Navigate to="/" />} />
      <Route path="/payment" element={isLoggedIn ? <PaymentScreen /> : <Navigate to="/" />} />
      <Route path="/summary" element={isLoggedIn ? <SummaryScreen /> : <Navigate to="/" />} />

      {/* Block access to /complete-signup if user doesn't need signup completion */}
      <Route path="/complete-signup" element={needsSignupCompletion ? <CompleteSignupScreen /> : <Navigate to="/servicehome" />} />


      {/* Admin Routes */}
      <Route path="/adminLogin" element={isLoggedIn ? (isOwner ? <Navigate to="/adminHome" /> : <Navigate to="/servicehome" />) : <AdminLoginScreen />} />
      <Route path="/adminHome" element={isOwner ? <AdminHome /> : <Navigate to="/adminLogin" />} />

      <Route path="/job-statuses" element={isOwner ? <JobStatuses /> : <Navigate to="/adminLogin" />} />
      <Route path="/job-statuses/:jobId" element={isOwner ? <JobDetailPage /> : <Navigate to="/adminLogin" />} />

      <Route path="/deleted-jobs" element={isOwner ? <DeletedJobs /> : <Navigate to="/adminLogin" />} />
      <Route path="/completed-jobs" element={isOwner ? <CompletedJobs /> : <Navigate to="/adminLogin" />} />
      <Route path="/customer-lookup" element={isOwner ? <CustomerLookup /> : <Navigate to="/adminLogin" />} />


      {/* New Route for Recurring Job Details */}
      <Route path="/recurring-job-status" element={isOwner ? <RecurringJobsStatus /> : <Navigate to="/adminLogin" />} />
      <Route path="/recurring-job-details/:recurringJobId" element={isOwner ? <RecurringJobDetailPage /> : <Navigate to="/adminLogin" />} />


    </Routes>
  );
}

export default App;




