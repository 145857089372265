// DataGridCard.js
import React from 'react';
import CustomHeader from './CustomHeader';
import DataDisplayTable from './DataDisplayTable';
import styles from './DataGridCard.module.css'; // Import the CSS module

const DataGridCard = ({ headerText, data, index, imageSrc }) => {
  return (
    <div className={styles.cardContainer}>
      
      <CustomHeader
        backgroundColor="black"
        textColor="white"
        text={headerText}
      />
      {imageSrc && <img src={imageSrc} alt="Profile" className={styles.profileImage} />}
      <DataDisplayTable dataArray={data} />
    </div>
  );
};

export default DataGridCard;
