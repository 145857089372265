// src/screens/DefineJobScreen.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';

import Checkbox from '../components/Checkbox';
import NumberIncrementor from '../components/NumberIncrementor';
import ErrorBox from '../components/ErrorBox';
import PriceEstimateDisplay from '../components/PriceEstimateDisplay';
import CheckListComponent from '../components/CheckListComponent';
import SectionHeader from '../components/SectionHeader';
import Header from '../components/Header';

import { updateJob } from '../slices/jobSlice';
import { getFirebaseToken } from '../utils/firebaseTokenUtil';


function DefineJobScreen() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const jobId = useSelector((state) => state.job.id);
  
  const cleaningType = useSelector((state) => state.job.cleaningType);
  const [squareFeet, setSquareFeet] = useState('');
  const [priceDetails, setPriceDetails] = useState({ priceTitle: "Estimated Price", price: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [priceError, setPriceError] = useState([]);
  const [checklistItems, setChecklistItems] = useState([]);
  const [numberBathrooms, setNumberBathrooms] = useState(1);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const propertyId = useSelector((state) => state.property.id);

  const user = useSelector((state) => state.user);
  const adminTargetUser = useSelector((state) => state.adminTargetUser);

  console.log("propertyId", propertyId);


  // Fetch checklist items
  useEffect(() => {
    const fetchChecklistItems = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/get-checklist-items`);
        setChecklistItems(response.data?.checkListItems || []);
      } catch (error) {
        console.error('Failed to fetch checklist items:', error);
      }
    };
    fetchChecklistItems();
  }, []);


  const handleSquareFeetChange = (event) => {
    const value = event.target.value;
    if (/^[1-9]\d*$/.test(value) || value === '') {
      setSquareFeet(value);
    }
  };


  const handleIncrementBathrooms = () => setNumberBathrooms((current) => current + 1);
  const handleDecrementBathrooms = () => setNumberBathrooms((current) => Math.max(1, current - 1));


  const handleCleaningTypeChange = (type) => {
    dispatch(updateJob({ cleaningType: type }));
    if (parseInt(squareFeet, 10) >= 500) {
      fetchPriceEstimate(type);
    } else {
      setSquareFeet('');
      setPriceDetails({ priceTitle: "Estimated Price", price: 0 });
      setPriceError([]);
    }
  };


  const fetchPriceEstimate = async (type) => {
    const errors = validateInputs(squareFeet, type);
    if (errors.length > 0) {
      setPriceError(errors);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setPriceError([]);
    try {
      const response = await axios.get(`${apiBaseUrl}/quick-price-estimate`, {
        params: { squareFeet, cleaningType: type, numberBathrooms }
      });
      const roundedPrice = roundToNearestFive(parseFloat(response.data?.regularEstimate || 0));
      setPriceDetails({ priceTitle: "Estimated Price With Tax", price: roundedPrice });
    } catch (err) {
      console.error('API Error:', err);
      setPriceError(['Failed to fetch estimate. Please try again.']);
    }
    setIsLoading(false);
  };


  const validateInputs = (squareFeet, cleaningType) => {
    const errors = [];
    if (!squareFeet || parseInt(squareFeet, 10) < 500) {
      errors.push("Please enter a square footage estimate of at least 500.");
    }
    if (!cleaningType) {
      errors.push("Please select a cleaning type.");
    }
    return errors;
  };


  const roundToNearestFive = (num) => Math.round(num / 5) * 5;


  // const updateJobBackend = async () => {
  //   try {
  //     const firebaseIdToken = await getFirebaseToken();
  //     const response = await axios.post(`${apiBaseUrl}/update-job`, {
  //       jobId: jobId,
  //       cleaningType
  //     }, {
  //       headers: { Authorization: `Bearer ${firebaseIdToken}` }
  //     });
  //     return response.status === 200;
  //   } catch (error) {
  //     console.error('Error updating job:', error);
  //     return false;
  //   }
  // };


  const updateJobBackend = async () => {
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token found');
  
      // Prepare the request body
      const requestBody = {
        jobId: jobId,
        cleaningType,
      };
  
      if (user.role === 'owner' && adminTargetUser.id) {
        requestBody.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }
  
      // Make the API call
      const response = await axios.post(
        `${apiBaseUrl}/update-job`,
        requestBody, // Pass the request body
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        }
      );
  
      return response.status === 200;
    } catch (error) {
      console.error('Error updating job:', error);
      return false;
    }
  };
  
  

  const handleNextPress = async () => {
    if (!cleaningType) {
      setShowError(true);
      return;
    }
    setShowError(false);
    setIsNextLoading(true);
    const updateSuccess = await updateJobBackend();
    setIsNextLoading(false);
    if (updateSuccess) {
      navigate('/property');
    }
  };


  const getGeneralChecklist = (serviceType) => {
    return checklistItems
      .filter(item => item.roomType === 'general' && item[`${serviceType}Service`])
      .sort((a, b) => a.generalOrder - b.generalOrder);
  };


  const getRoomTypeChecklist = (roomType, serviceType) => {
    return checklistItems
      .filter(item => item.roomType === roomType && item[`${serviceType}Service`])
      .sort((a, b) => a[`${roomType}Order`] - b[`${roomType}Order`]);
  };

  const standardBedroomItems = getRoomTypeChecklist('bedroom', "standard");
  const deepBedroomItems = getRoomTypeChecklist('bedroom', "deep");

  return (
    <div className="full-width-container with-padding-bottom">
      <Header />

      <div className="content-container column">       

        <div className='form-container'>
            <h1>Define Job</h1>
        </div>

        <SectionHeader 
          text="Cleaning Type" 
          backgroundColor="#333333" 
          fontColor="white" 
          textAlign="left"
          marginTop={0}
          marginBottom={10}
          padding={5}
        />  

        <section >   
          <div >
            <div>
              <Checkbox
                label="Standard Clean"
                checked={cleaningType === 'standard'}
                onChange={() => handleCleaningTypeChange('standard')}
              />
              <Checkbox
                label="Deep Clean"
                checked={cleaningType === 'deep'}
                onChange={() => handleCleaningTypeChange('deep')}
              />
            </div>

            {showError && <ErrorBox errorTexts={["Please select a cleaning type"]} />}
          </div>


          <SectionHeader 
            text="Quick Price Estimate (optional)" 
            backgroundColor="#333333" 
            fontColor="white" 
            textAlign="left"
            marginTop={10}
            marginBottom={10}
            padding={5}
          />  





          <div>
              <input
                type="number"
                placeholder="Square Footage Estimate"
                value={squareFeet}
                onChange={handleSquareFeetChange}
                min="500"
                inputMode="numeric"
                pattern="[0-9]*"
                className="estimate-input"
              />

              <label className="form-label">Number of Bathrooms:</label>

              <NumberIncrementor
                value={numberBathrooms}
                onIncrement={handleIncrementBathrooms}
                onDecrement={handleDecrementBathrooms}
              />
              <button
                className="get-estimate-button margin10"
                onClick={() => fetchPriceEstimate(cleaningType)}
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Get Estimate'}
              </button>
              <PriceEstimateDisplay priceDetails={priceDetails} />
              <ErrorBox errorTexts={priceError} />
          </div>





        </section>

        <section className="content-container checklist-grid-container">
          <div className={`checklist-column ${cleaningType === "standard" ? "selected-type" : ""}`}>
            <SectionHeader
              text="Standard Clean Checklist"
              backgroundColor="#000000"
              fontColor="#FFFFFF"
              textAlign="center"
              fontSize="large"
            />
            
            <div className="checklist-section">
              <h2>All Rooms - Standard</h2>
              <CheckListComponent
                items={getGeneralChecklist("standard")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            
            <div className="checklist-section">
              <h2>Bathrooms - Standard</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('bathroom', "standard")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            
            {standardBedroomItems.length > 0 && (
              <div className="checklist-section">
                <h2>Bedrooms - Standard</h2>
                <CheckListComponent
                  items={standardBedroomItems}
                  marginLeft={15}
                  marginBottom={15}
                />
              </div>
            )}
            
            <div className="checklist-section">
              <h2>Kitchen - Standard</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('kitchen', "standard")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          </div>

          <div className={`checklist-column ${cleaningType === "deep" ? "selected-type" : ""}`}>
            <SectionHeader
              text="Deep Clean Checklist"
              backgroundColor="#000000"
              fontColor="#FFFFFF"
              textAlign="center"
              fontSize="large"
            />
            
            <div className="checklist-section">
              <h2>All Rooms - Deep</h2>
              <CheckListComponent
                items={getGeneralChecklist("deep")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            
            <div className="checklist-section">
              <h2>Bathrooms - Deep</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('bathroom', "deep")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
            
            {deepBedroomItems.length > 0 && (
              <div className="checklist-section">
                <h2>Bedrooms - Deep</h2>
                <CheckListComponent
                  items={deepBedroomItems}
                  marginLeft={15}
                  marginBottom={15}
                />
              </div>
            )}
            
            <div className="checklist-section">
              <h2>Kitchen - Deep</h2>
              <CheckListComponent
                items={getRoomTypeChecklist('kitchen', "deep")}
                marginLeft={15}
                marginBottom={15}
              />
            </div>
          </div>
        </section>
      </div>

      {/* Fixed Bottom Bar for "Next" Button */}
      <div className="fixed-bottom-bar">
        <div className="button-bar">
          <button
            className="blue-button"
            onClick={handleNextPress}
            disabled={isNextLoading || !cleaningType}
          >
            {isNextLoading ? 'Loading...' : 'Next'}
          </button>
        </div>
      </div>

    </div>
  );
}

export default DefineJobScreen;