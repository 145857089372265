// src/components/DayBlock.js

import React from 'react';
import './DayBlock.css';

const DayBlock = ({ day, onPress, isSelected }) => {
  return (
    <div 
      className={`day-block ${isSelected ? 'day-selected' : ''}`} 
      onClick={() => onPress(day)}
    >
      <span className="day-text">{day.substring(0, 3).toUpperCase()}</span>
    </div>
  );
};

export default DayBlock;
