import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Header from '../../components/Header';
import Breadcrumbs from '../../components/admin/Breadcrumbs';
import Button from '../../components/admin/Button';

import GoogleMapComponent from '../../components/admin/GoogleMapComponent';
import SectionHeader from '../../components/SectionHeader';
import DataGridCard from '../../components/admin/DataGridCard';
import CustomHeader from '../../components/admin/CustomHeader';
import HoverTable from '../../components/admin/HoverTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import { apiBaseUrl } from '../../apiConfig';
import { getFirebaseToken } from '../../utils/firebaseTokenUtil';



const RecurringJobDetailPage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { recurringJobId } = useParams(); 

  const [recurringJobDetails, setRecurringJobDetails] = useState(null);
  const [jobLocation, setJobLocation] = useState(null);
  const [cleanersData, setCleanersData] = useState([]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);


  // Static breadcrumbs for this page
  const breadcrumbs = [
    { name: 'Home', route: '/adminHome' },
    { name: 'Recurring Job Details', route: '' },
  ];


  // Fetch recurring job details
  const fetchRecurringJobDetails = async () => {
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');

      // Make the API call with the ID token in the headers
      const response = await axios.get(`${apiBaseUrl}/admin/fetch-recurring-jobs-with-details`, {
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
        params: { recurringJobId }, // Pass recurringJobId as a parameter
      });

      const { jobData, cleaners } = response.data;

      if (jobData && jobData.length > 0) {
        const jobDetails = jobData[0].job; 

        setRecurringJobDetails(jobDetails);

        // Set location from property data if available
        if (jobDetails.property && jobDetails.property.latitude && jobDetails.property.longitude) {
          setJobLocation({
            lat: jobDetails.property.latitude,
            lng: jobDetails.property.longitude,
          });
        }
      } else {
        setRecurringJobDetails(null); // Reset job details if no data is found
      }

      // Set cleaners data
      if (cleaners && cleaners.length > 0) {
        setCleanersData(cleaners);
      } else {
        setCleanersData([]); // Ensure it's an empty array if no cleaners are found
      }
    } catch (error) {
      console.error('Error fetching recurring job details:', error);
      setRecurringJobDetails(null);
      setCleanersData([]); // Reset cleaners data on error
    }
  };

  // Use the function in useEffect
  useEffect(() => {
    fetchRecurringJobDetails();
  }, [recurringJobId]);




  const handleDeleteRecurringJob = async () => {
    setIsDeleting(true);
    try {
      // Get the Firebase ID token for authentication
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');
  
      // Make the API call to delete the recurring job
      await axios.post(
        `${apiBaseUrl}/remove-recurring-scheduled-job`,
        { recurringJobId }, // Pass the recurringJobId in the body
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } } // Include the token in headers
      );
  
      setShowDeleteConfirmation(false); // Close confirmation dialog
      await fetchRecurringJobDetails(); // Refresh job details
    } catch (error) {
      console.error('Error deleting recurring job:', error);
    } finally {
      setIsDeleting(false);
    }
  };
  




  const handleConfirmDelete = () => {
    handleDeleteRecurringJob();
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };


  // Render job details
  const propertyDetailsData = recurringJobDetails
    ? [
        { name: 'Address', data: recurringJobDetails.property.formattedAddress || 'N/A' },
        { name: '# Bedrooms', data: recurringJobDetails.property.numberBedrooms || 'N/A' },
        { name: '# Bathrooms', data: recurringJobDetails.property.numberBathrooms || 'N/A' },
        { name: 'Square Feet', data: recurringJobDetails.property.squareFeet || 'N/A' },
        { name: '# Dogs', data: recurringJobDetails.property.numberDogs || 0 },
        { name: '# Cats', data: recurringJobDetails.property.numberCats || 0 },
      ]
    : [];


  const jobDetailsData = recurringJobDetails
    ? [
        { name: 'Service Frequency', data: recurringJobDetails.serviceFrequency },
        { name: 'Desired Service Day', data: recurringJobDetails.desiredServiceDayOfWeek },
        { name: 'Period Frequency Label', data: recurringJobDetails.periodFrequencyLabel },
        { name: 'Service Activated', data: recurringJobDetails.serviceActivated ? 'Yes' : 'No' },
      ]
    : [];



  // Headers for Derived Jobs HoverTable
  const derivedJobHeaders = [
    'Job ID',
    'Cleaning Type',
    'Estimated Clean Time',
    'Number of Cleaners',
    'Order Submitted',
  ];


  // Map the DerivedJobs data for HoverTable
  const derivedJobsData = recurringJobDetails?.DerivedJobs?.map((job) => ({
    rowContent: [
      { type: 'text', content: job.id },
      { type: 'text', content: job.cleaningType },
      { type: 'text', content: `${job.estimatedCleanTime || 0} hrs` },
      { type: 'text', content: job.numberOfCleaners || 'N/A' },
      { type: 'text', content: job.orderSubmittedBinary ? 'Yes' : 'No' },
    ],
  })) || [];






  const renderCleanerData = (cleaner) => [
    { name: 'Phone #', data: cleaner.phone, type: 'phone' },
    { name: 'Email', data: cleaner.email, type: 'email' },
    { name: 'Role', data: cleaner.role, type: 'text' }, 
    // Add other fields as needed
  ];



  


  const handleDerivedJobRowClick = (job) => {
    // Extract the `jobId` from the rowContent array
    const jobId = job.rowContent[0]?.content;
  
    if (jobId) {
      console.log('Derived Job clicked:', job);
      navigate(`/job-statuses/${jobId}`); // Navigate to the job detail page
    } else {
      console.error('Invalid job data:', job);
    }
  };



  const getSectionHeaderText = (details) => {
    switch (true) {
      case details.jobDeletedBinary:
        return 'DELETED';
      case details.serviceActivated:
        return 'ACTIVE';
      default:
        return 'INACTIVE';
    }
  };
  
  const getSectionHeaderBackgroundColor = (details) => {
    switch (true) {
      case details.jobDeletedBinary:
        return 'red';
      case details.serviceActivated:
        return 'green';
      default:
        return 'red';
    }
  };
  
    

  return (
    <div className="full-width-container">

      <Header />

      {/* Deletion confirmation modal */}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          message="Are you sure you want to delete this recurring job? This action cannot be undone."
          onConfirm={handleConfirmDelete} // Handle "Yes" option
          onCancel={handleCancelDelete}   // Handle "No" option
        />
      )}


      <div className="content-container column">
        <Breadcrumbs crumbs={breadcrumbs} />

        <h1>
          Recurring Job Details: {recurringJobDetails ? recurringJobDetails.property.address : 'Loading...'}
        </h1>


        {/* Section Header */}
        {recurringJobDetails && (
          <SectionHeader
            text={getSectionHeaderText(recurringJobDetails)}
            backgroundColor={getSectionHeaderBackgroundColor(recurringJobDetails)}
            fontColor="white"
            marginBottom={10}
            padding={10}
          />
        )}

        {/* Google Map Component */}
        {jobLocation ? (
          <GoogleMapComponent location={jobLocation} />
        ) : (
          <div>Loading map...</div>
        )}

        <br />

        {/* Overview Section */}
        <CustomHeader backgroundColor="#000099" textColor="white" text="Overview" />
        <div style={gridContainerStyle}>
          {/* Property Card */}
          <DataGridCard headerText="Property" data={propertyDetailsData} />

          {/* Recurring Job Card */}
          <DataGridCard headerText="Recurring Job" data={jobDetailsData} />


          {/* Actions Card */}
          <div style={cardContainerStyle}>
            <CustomHeader backgroundColor="black" textColor="white" text="Actions" />
            <div style={buttonWrapperStyle}>
              <Button
                color="red"
                width="210px"
                onClick={() => setShowDeleteConfirmation(true)}
                isLoading={isDeleting} // Show loading state
                disabled={isDeleting || recurringJobDetails?.jobDeletedBinary} // Disable if already deleted or deleting
              >
                Delete Recurring Job
              </Button>
            </div>
          </div>



        </div>

        <br />  


        {/* Cleaners Section */}
        <CustomHeader backgroundColor="#000099" textColor="white" text="Cleaners" />
        <div style={gridContainerStyle}>
          {cleanersData.length > 0 ? (
            cleanersData.map((cleaner, index) => (
              <DataGridCard
                key={index}
                headerText={`${cleaner.lastName}, ${cleaner.firstName}`}
                data={renderCleanerData(cleaner)} // Pass the formatted data with types
                imageSrc={cleaner.userProfilePhoto}
              />
            ))
          ) : (
            <div className="no-jobs-message">No cleaners assigned</div>
          )}
        </div>





        <br /> 

        {recurringJobDetails && (
          <>
            <CustomHeader backgroundColor="#000099" textColor="white" text="Derived Jobs" />
            {derivedJobsData.length > 0 ? (
              <HoverTable
                headers={derivedJobHeaders}
                data={derivedJobsData.map((job) => job.rowContent)}
                onRowClick={(index) => handleDerivedJobRowClick(derivedJobsData[index])}
              />
            ) : (
              <div className="no-jobs-message">No derived jobs available</div>
            )}
          </>
        )}


        <br />

      </div>
    </div>
  );



};


const cardContainerStyle = {
  border: '1px solid #ccc',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: '100%'
};
  
const buttonWrapperStyle = {
  padding: '10px', // Add padding if necessary
  flexGrow: 1, // Flex grow to take up all available space
  display: 'flex',
  justifyContent: 'center', // Center button horizontally
  alignItems: 'center', // Center button vertically
};


// Inline styles for the grid layout
const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
};


export default RecurringJobDetailPage;
