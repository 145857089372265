// src/screens/ServiceHome.js
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation  } from 'react-router-dom';

import Header from '../components/Header';
import LoadingIndicator from '../components/LoadingIndicator';
import UnfinishedScheduling from '../components/UnfinishedScheduling';
import ScheduledJob from '../components/ScheduledJob';

import { updateJob } from '../slices/jobSlice';
import { updateProperty } from '../slices/propertySlice';
import { updateProperties } from '../slices/propertiesSlice';
import { setAssignedPropertyIds } from '../slices/assignedPropertiesSlice';
import { setCardInformation, resetCardInformation } from '../slices/selectedCardInformationSlice';
import { setRecurringDay, resetRecurringDay } from '../slices/desiredRecurringDaySlice';
import { updateTasks } from '../slices/tasksSlice';
import { setDate, resetDate } from '../slices/desiredDateSlice';
import { setFrequency, resetFrequency } from '../slices/serviceFrequencySlice';
import { setAllChecklistItems } from '../slices/allChecklistItemsSlice';

import { setSoloCleaner, setMultipleCleaners, resetCleaners } from '../slices/desiredCleanersSlice';
import { setAvailableRecurringDays, resetAvailableRecurringDays } from '../slices/availableRecurringDaysSlice';
import { setRecurringSoloDayCleaners, resetRecurringSoloDayCleaners } from '../slices/recurringSoloDayCleanersSlice';
import { setRecurringMultiDayCleaners, resetRecurringMultiDayCleaners } from '../slices/recurringMultiDayCleanersSlice';


import axios from 'axios';
import { apiBaseUrl } from '../apiConfig';
import { getFirebaseToken } from '../utils/firebaseTokenUtil';
import { taskSubscriptionManager } from '../thunks/taskSubscriptionManager';
import { constructHomeScreenItemsFromTasks } from '../utils/taskUtils';

// Utility function to match unscheduled jobs to properties
const lookupUnscheduledProperty = (unscheduledJob, properties) => {
  if (!unscheduledJob || !Array.isArray(properties)) return null;
  return properties.find((property) => property.id === unscheduledJob.propertyId) || null;
};

function ServiceHome() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [unscheduledJob, setUnscheduledJob] = useState(null);
  const [scheduledJobs, setScheduledJobs] = useState([]);
  const [scheduleSlots, setScheduleSlots] = useState([]);
  const [cleaners, setCleaners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewOrderLoading, setIsNewOrderLoading] = useState(false);

  const savedTasks = useSelector((state) => state.tasks);
  const propertiesState = useSelector((state) => state.properties);
  const subscribedTaskImages = useSelector(state => state.subscribedTasks);
  const unscheduledProperty = lookupUnscheduledProperty(unscheduledJob, propertiesState);

  const desiredDate = useSelector((state) => state.desiredDate);

  const user = useSelector((state) => state.user);
  const adminTargetUser = useSelector((state) => state.adminTargetUser);




  console.log("user", user);

  useEffect(() => {
    // Dispatch actions to reset state whenever the location changes to this route
    dispatch(resetAvailableRecurringDays());
    dispatch(resetRecurringSoloDayCleaners());
    dispatch(resetRecurringMultiDayCleaners());
    dispatch(resetCleaners());
    dispatch(resetFrequency());
    dispatch(resetRecurringDay());
    dispatch(resetDate());
  }, [dispatch, location]); // This will trigger when `location` changes


  // Log the value to the console whenever it changes
  useEffect(() => {
    console.log('Current desiredDate:', desiredDate);
  }, [desiredDate]); // This will run whenever desiredDate changes


  // Fetch jobs and update relevant state
  const fetchJobs = useCallback(async () => {
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');

      // Prepare the request parameters
      const params = {};
      if (user.role === 'owner' && adminTargetUser.id) {
        params.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }

      // Make the API call
      const response = await axios.get(`${apiBaseUrl}/home-screen-jobs`, {
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
        params,
      });

      console.log("jobs", response.data);

      // Update state with the response data
      setUnscheduledJob(response.data.unscheduledJob);
      setScheduledJobs(response.data.scheduledJobs);
      setScheduleSlots(response.data.scheduleSlots);
      setCleaners(response.data.cleaners);

      console.log("response.data.savedTasks", response.data.savedTasks);

      // Update Redux state
      if (response.data.unscheduledJob) dispatch(updateJob(response.data.unscheduledJob));
      if (response.data.savedProperties) dispatch(updateProperties(response.data.savedProperties));
      if (response.data.assignedProperties) dispatch(setAssignedPropertyIds(response.data.assignedProperties));
      if (response.data.unscheduledProperty) dispatch(updateProperty(response.data.unscheduledProperty));
      if (response.data.savedTasks?.length) {
        dispatch(updateTasks(response.data.savedTasks));
        dispatch(taskSubscriptionManager({ type: 'sync', tasks: response.data.savedTasks })); // Sync task subscriptions
      }
      if (response.data.checkListItems) dispatch(setAllChecklistItems(response.data.checkListItems));
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, user, adminTargetUser]);



  // Load job data and navigate for scheduled job press
  const handleScheduledJobPress = (jobId) => {
    fetchJobDataAndNavigate(jobId);
  };


  const fetchJobDataAndNavigate = async (jobId) => {
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');
  
      // Prepare the request parameters
      const params = {};
      if (user.role === 'owner' && adminTargetUser.id) {
        params.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }
  
      // Make the API call
      const response = await axios.get(`${apiBaseUrl}/load-submitted-job-data/${jobId}`, {
        headers: { Authorization: `Bearer ${firebaseIdToken}` },
        params, // Pass the parameters
      });
  
      // Dispatch responses to update the Redux state
      if (response.data.job) dispatch(updateJob(response.data.job));
      if (response.data.property) dispatch(updateProperty(response.data.property));
      if (response.data.tasks) dispatch(updateTasks(response.data.tasks));
      if (response.data.cardData) dispatch(setCardInformation(response.data.cardData));
      if (response.data.desiredServiceDayOfWeek) dispatch(setRecurringDay(response.data.desiredServiceDayOfWeek));
      if (response.data.serviceFrequency) dispatch(setFrequency(response.data.serviceFrequency));
      if (response.data.jobDateTime?.date) dispatch(setDate(response.data.jobDateTime.date));
  
      console.log("Navigating to SummaryScreen with loaded job data");
  
      // Navigate to the SummaryScreen with orderSubmittedBinary set to true
      navigate('/summary', { state: { orderSubmittedBinary: true } });
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };


  // This function will be passed down to UnfinishedScheduling
  const handleUnscheduledJobPress = () => {
    //need to have this function load all values from backend
    navigate('/definejob');
  };


  // Handle creation of a new order
  const handleNewOrder = async () => {
    setIsNewOrderLoading(true);
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token found');

      // Prepare the request body
      const requestBody = {};
      if (user.role === 'owner' && adminTargetUser.id) {
        requestBody.adminUserIdQuery = adminTargetUser.id; // Add admin target user ID for impersonation
      }

      // Make the API call
      const response = await axios.post(
        `${apiBaseUrl}/create-order-assign-property`,
        requestBody, // Pass the request body
        {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        }
      );

      console.log(response.data.savedProperties, response.data.savedProperties);

      // Dispatch responses to update the Redux state
      dispatch(updateJob(response.data.job));
      dispatch(updateProperty(response.data.property));
      dispatch(updateProperties(response.data.savedProperties));
      if (response.data.assignedProperties) dispatch(setAssignedPropertyIds(response.data.assignedProperties));

      // Navigate to DefineJobScreen after updating the Redux state
      navigate('/definejob');
    } catch (error) {
      console.error('Error creating new order:', error);
    } finally {
      setIsNewOrderLoading(false);
    }
  };


  // Trigger fetching of jobs on initial render
  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  return (
    <div className="full-width-container">
      <Header />
      <div className="content-container column">

        <div className='form-container'>
            <h1>Welcome to Service Home!</h1>
        </div>

        <div className="button-container">
          <button onClick={handleNewOrder} className="blue-button" disabled={isNewOrderLoading}>
            {isNewOrderLoading ? 'Creating New Order...' : 'New House Cleaning'}
          </button>
        </div>

        <LoadingIndicator isLoading={isLoading} text="Loading jobs..." />

        {/* Display unscheduled job if present */}
        {!isLoading && unscheduledJob && (
          <div className="section-container">
            <UnfinishedScheduling
              items={constructHomeScreenItemsFromTasks(savedTasks, unscheduledJob.propertyId, subscribedTaskImages)}
              unscheduledJob={unscheduledJob}
              property={unscheduledProperty}
              onPress={handleUnscheduledJobPress}
            />
          </div>
        )}

        {/* Display scheduled jobs if available */}
        {!isLoading && scheduledJobs.length > 0 && (
          <div className="section-container">
            <h2>Scheduled House Cleanings ({scheduledJobs.length})</h2>
            {scheduledJobs.map((job) => (
              <ScheduledJob
                key={job.id}
                data={job}
                scheduleSlots={scheduleSlots}
                imageItems={constructHomeScreenItemsFromTasks(savedTasks, job.propertyId, subscribedTaskImages)}
                onJobPress={handleScheduledJobPress}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceHome;







