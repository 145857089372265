// src/components/DaysContainer.js

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DayBlock from './DayBlock';
import './DaysContainer.css';

const DaysContainer = ({ days, onDayPress, frequency, isLoading, areCleanersAvailable }) => {
  const [selectedDay, setSelectedDay] = useState(null);

  // Accessing the Redux state
  const selectedDayRedux = useSelector(state => state.desiredRecurringDay);
  
  // useEffect to monitor changes in areCleanersAvailable
  useEffect(() => {
    if (!areCleanersAvailable) {
      setSelectedDay(null);
    }
  }, [areCleanersAvailable]);

  useEffect(() => {
    // Check if selectedDayRedux is not an empty string and different from selectedDay
    if (selectedDayRedux !== '' && selectedDayRedux !== selectedDay) {
      setSelectedDay(selectedDayRedux);
    }
  }, [selectedDayRedux, selectedDay]);

  // Function to handle when a day is pressed
  const handleDayPress = (day, frequency) => {
    // Set the selected day
    setSelectedDay(day);
    // Call the passed in onDayPress function
    onDayPress(day, frequency);
  };

  return (
    <div className="day-container">
      {/* Title Text based on days array length */}
      {!isLoading && (
        <span className="day-titleText">
          {days.length > 0 ? 'Select day for cleaner options:' : 'No one currently available'}
        </span>
      )}

      {/* Render the day blocks */}
      <div className="day-dayBlocks">
        {days.map((day, index) => (
          <DayBlock 
            key={index} 
            day={day} 
            isSelected={day === selectedDay}  // Pass isSelected prop to DayBlock
            onPress={() => handleDayPress(day, frequency)}
          />
        ))}
      </div>
    </div>
  );
};

export default DaysContainer;
