// src/slices/allChecklistItemsSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const allChecklistItemsSlice = createSlice({
  name: 'allChecklistItems',
  initialState: {
    items: { bathroom: [], bedroom: [], kitchen: [], general: [] }
  },
  reducers: {
    setAllChecklistItems: (state, action) => {
      state.items = action.payload;
    },
    clearAllChecklistItems: (state) => {
      state.items = { bathroom: [], bedroom: [], kitchen: [], general: [] };
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAllChecklistItems, clearAllChecklistItems } = allChecklistItemsSlice.actions;

export default allChecklistItemsSlice.reducer;
