// src/screens/admin/DeletedJobs.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Breadcrumbs from '../../components/admin/Breadcrumbs';
import HoverTable from '../../components/admin/HoverTable'; 

import Header from '../../components/Header'; 
import { apiBaseUrl } from '../../apiConfig';
import { getFirebaseToken } from '../../utils/firebaseTokenUtil';

const headers = [
  'Status', 
  'Job Date',
  '# Cleaners',
  'Est. Clean Time',
  'Customer Name',
  'Address',
  'Cleaning Type',
];

// Define breadcrumbs data
const breadcrumbs = [
  { name: 'Dashboard', route: '/adminHome' },
  { name: 'Deleted Jobs', route: '/deleted-jobs' },
];

const DeletedJobs = () => {
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]); // State to store jobs data

  // Map the jobs data to the format needed for HoverTable, including the jobId for use in onRowClick
  const jobStatusData = jobsData.map((job) => {
    return {
      rowContent: [
        { type: 'text', content: 'Deleted' }, // Hardcoded 'Deleted' status
        { type: 'text', content: job.date },
        { type: 'text', content: job.numberOfCleaners.toString() },
        { type: 'text', content: `${job.estimatedCleanTime} hrs` },
        { type: 'text', content: job.customerName },
        { type: 'text', content: job.address },
        { type: 'text', content: job.cleaningType },
      ],
      jobId: job.jobId, // Add jobId here
    };
  });

  useEffect(() => {
    // Fetch jobs when the component mounts
    const fetchJobs = async () => {
      try {
        // Get Firebase ID token for authentication
        const firebaseIdToken = await getFirebaseToken();
        if (!firebaseIdToken) throw new Error('No Firebase ID token');

        // Make the API call with authentication headers
        const response = await axios.get(`${apiBaseUrl}/admin/fetch-jobs`, {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
          params: { geographicRegionId: 1, deletedJobsOnly: true }, // Pass deletedJobsOnly as true
        });

        // Set the response data to state
        setJobsData(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []); // Empty dependency array to run this effect only once when the component mounts

  
  // Update handleRowClick to use the job object
  const handleRowClick = (job) => {
    console.log('Job clicked:', job);
    // Navigate to the job detail page using the jobId
    navigate(`/job-statuses/${job.jobId}`);
  };


  return (
    <div className="full-width-container">
      {/* Full-width Header Section */}
      <Header />

      {/* Main content section */}
      <div className="content-container column">
        <h1>Deleted Jobs</h1>
        <Breadcrumbs crumbs={breadcrumbs} />

        <HoverTable
          headers={headers}
          data={jobStatusData.map((job) => job.rowContent)} // Pass only the row content to be rendered
          onRowClick={(index) => handleRowClick(jobsData[index])} // Pass the entire job object to onRowClick
        />

        {/* Content specific to the Deleted Jobs page goes here */}
      </div>
    </div>
  );
};

export default DeletedJobs;
