import React from 'react';
import './Checkbox.css';

const checkedImage = '/images/icons/checked.png';
const uncheckedImage = '/images/icons/unchecked.png';

const Checkbox = ({ checked, onChange, label, marginBottom, fontSize = '1.2em', readOnly = false }) => {
  const imageSource = checked ? checkedImage : uncheckedImage;

  const handleClick = () => {
    if (!readOnly && onChange) {
      onChange(!checked);
    }
  };

  const containerStyle = { marginBottom: marginBottom || 0 };
  const labelStyle = { fontSize };

  return (
    <div className="checkbox-container" style={containerStyle}>
      <button onClick={handleClick} className="checkbox-button" disabled={readOnly}>
        <img src={imageSource} alt="" className="checkbox-image" />
      </button>
      <div className="checkbox-label" style={labelStyle}>
        <span>{label}</span>
      </div>
    </div>
  );
};

export default Checkbox;

