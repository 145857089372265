import { createSlice } from '@reduxjs/toolkit';

// Initial state is now an empty array
const initialState = [];

const subscribedCleanerPhotosSlice = createSlice({
  name: 'subscribedCleanerPhotos',
  initialState,
  reducers: {
    // Reducer to add or update cleaner photos
    addOrUpdateCleanerPhotos: (state, action) => {
      const newPhoto = action.payload;
      const index = state.findIndex(photo => photo.id === newPhoto.id);

      if (index !== -1) {
        state[index] = newPhoto; // Update existing photo
      } else {
        state.push(newPhoto); // Add new photo
      }
    },
    // Reducer to remove a cleaner photo by id
    removeCleanerPhotos: (state, action) => {
      return state.filter(photo => !action.payload.includes(photo.id));
    },
    // Reducer to reset the state to its initial state
    resetCleanerPhotos: () => {
      return initialState;
    }
  },
});

export const { addOrUpdateCleanerPhotos, removeCleanerPhotos, resetCleanerPhotos } = subscribedCleanerPhotosSlice.actions;
export default subscribedCleanerPhotosSlice.reducer;
