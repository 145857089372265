// src/slices/tasksSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    updateTasks: (state, action) => {
      return action.payload;
    },
    updateSpecificTask: (state, action) => {
      // Existing reducer to update the entire task object
      return state.map(task => 
        task.id === action.payload.id ? action.payload : task
      );
    },
    updateTaskText: (state, action) => {
      const { id, taskCustomerText } = action.payload;
      
      console.log(`Updating task with id ${id} with new text: ${taskCustomerText}`);  // Debug log for tracking updates
      
      return state.map(task => {
        if (task.id === id) {
          console.log(`Before update: ${JSON.stringify(task)}`);  // Debug log to see task state before update
          const updatedTask = { ...task, taskCustomerText };
          console.log(`After update: ${JSON.stringify(updatedTask)}`);  // Debug log to see task state after update
          return updatedTask;
        }
        return task;
      });
    }
  },
});

export const { updateTasks, updateSpecificTask, updateTaskText } = tasksSlice.actions;
export default tasksSlice.reducer;
