// src/slices/scheduleSlotsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const scheduleSlotsSlice = createSlice({
  name: 'scheduleSlots',
  initialState,
  reducers: {
    updateScheduleSlots: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateScheduleSlots } = scheduleSlotsSlice.actions;
export default scheduleSlotsSlice.reducer;
