// src/screens/AdminLoginScreen.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';  // Firebase function to handle email sign-in
import { auth } from '../../firebaseConfig';  // Import Firebase Auth instance

import './AdminLoginScreen.css';  // Import the CSS file for styling

import Header from '../../components/Header';  // Import the Header component
import ErrorBox from '../../components/ErrorBox';  // Import the ErrorBox component

function AdminLoginScreen() {
  const [email, setEmail] = useState('');  // Store the user's email
  const [password, setPassword] = useState('');  // Store the user's password
  const [error, setError] = useState('');  // Store any errors during login

  // Function to handle sign-in with email and password
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User signed in:', user);
    } catch (err) {
      setError('Failed to sign in. Please check your email and password.');
      console.error('Error during sign-in:', err);
    }
  };

  return (
    <div className="email-login-screen">
      <Header /> {/* Include the header component */}
      
      <div className="email-login-container">
        <h1 className="login-title">Sparkle Employee Login</h1>

        <form onSubmit={handleLogin} className="login-form">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@example.com"
            required
            className="login-input"
          />
          
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Your Password"
            required
            className="login-input"
          />

          <button type="submit" className="login-button">Sign In</button>
        </form>

        {error && <ErrorBox errorTexts={[error]} />} {/* Display error using the ErrorBox */}
      </div>
    </div>
  );
}

export default AdminLoginScreen;
