import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/admin/Button'; 
import Header from '../../components/Header';  // Import the Header component

const AdminHome = () => {
  let navigate = useNavigate();

  return (
    <div className="full-width-container">
      {/* Full-width Header Section */}
      <Header />

      {/* Welcome Message and Button Group inside container */}
      <div className="content-container column">
        {/* Welcome Message */}
        <div className="welcome">
          <h1>Welcome, Peter Troutman</h1>
        </div>
      
        {/* Button Group */}

        <div className="button-group">       
          <Button width="200px" onClick={() => navigate('/job-statuses')}>Active Jobs</Button> 
          <Button width="200px" onClick={() => navigate('/deleted-jobs')}>Deleted Jobs</Button> 
          <Button width="200px" onClick={() => navigate('/completed-jobs')}>Completed Jobs</Button> 
          <Button width="200px" onClick={() => navigate('/customer-lookup')}>Customer Lookup</Button>
          <Button width="200px" onClick={() => navigate('/recurring-job-status')}>Recurring Jobs</Button>
        </div>



      </div>
    </div>
  );
};

export default AdminHome;
