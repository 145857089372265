// src/slices/propertiesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    updateProperties: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateProperties } = propertiesSlice.actions;
export default propertiesSlice.reducer;
