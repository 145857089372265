// src/components/CleanersContainer.js
import React, { useState, useEffect } from 'react';
import CleanerBlock from './CleanerBlock';
import UnselectedCleanerBlock from './UnselectedCleanerBlock';

import styles from './CleanersContainer.module.css'; // Import the CSS module properly

const CleanersContainer = ({ cleaners, numberOfCleaners, onSelectedCleanersChange }) => {
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const [filteredCleaners, setFilteredCleaners] = useState([]);

  const handleCleanerSelection = (cleaner) => {
    let updatedSelectedCleaners;
    if (selectedCleaners.includes(cleaner)) {
      updatedSelectedCleaners = selectedCleaners.filter(item => item !== cleaner);
      setSelectedCleaners(updatedSelectedCleaners);
    } else if (numberOfCleaners === 1 && selectedCleaners.length === 1) {
      updatedSelectedCleaners = [cleaner];
      setSelectedCleaners(updatedSelectedCleaners);
    } else {
      updatedSelectedCleaners = [...selectedCleaners, cleaner];
      setSelectedCleaners(updatedSelectedCleaners);
    }
    updateFilteredCleaners(updatedSelectedCleaners);
  };

  const updateFilteredCleaners = (currentSelectedCleaners) => {
    const updatedFilteredCleaners = cleaners.filter(cleaner => 
      !currentSelectedCleaners.some(selected => 
        selected.cleanerUserId === cleaner.cleanerUserId &&
        selected.shiftType === cleaner.shiftType
      )
    );
    setFilteredCleaners(updatedFilteredCleaners);
  };

  useEffect(() => {
    const validSelectedCleaners = selectedCleaners.filter(selectedCleaner =>
      cleaners.some(
        cleaner => 
          cleaner.cleanerUserId === selectedCleaner.cleanerUserId && 
          cleaner.shiftType === selectedCleaner.shiftType
      )
    );
    
    setSelectedCleaners(validSelectedCleaners);
    updateFilteredCleaners(validSelectedCleaners);
  }, [cleaners]);

  useEffect(() => {
    onSelectedCleanersChange(selectedCleaners);
  }, [selectedCleaners]);

  return (
    <div className={styles.container}>
      
      <div className={styles.cleanerRow}>
        {selectedCleaners.map((cleaner, index) => (
          <CleanerBlock
            key={`selected-${index}`}
            cleaner={cleaner}
            selected={true}
            onPress={() => handleCleanerSelection(cleaner)}
          />
        ))}

        {Array.from({ length: numberOfCleaners - selectedCleaners.length }).map((_, index) => (
          <UnselectedCleanerBlock key={`unselected-${index}`} />
        ))}
      </div>

      <span className={styles.titleText}>Cleaner Selection (optional)</span>

      <div className={styles.cleanerRow}>
        {filteredCleaners.map((cleaner, index) => (
          <CleanerBlock
            key={`cleaner-${index}`}
            cleaner={cleaner}
            onPress={() => handleCleanerSelection(cleaner)}
          />
        ))}
      </div>
    </div>
  );
};

export default CleanersContainer;
