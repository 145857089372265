// src/components/UnfinishedScheduling.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImageRow from './ImageRow';
import './UnfinishedScheduling.css';

const UnfinishedScheduling = ({ items, onPress, unscheduledJob, property }) => {
  const navigate = useNavigate();

  let cleaningTypeText = '';
  switch (unscheduledJob?.cleaningType) {
    case 'deep':
      cleaningTypeText = 'Deep Clean';
      break;
    case 'standard':
      cleaningTypeText = 'Standard Clean';
      break;
    case 'moveout':
      cleaningTypeText = 'Move Out Clean';
      break;
    default:
      cleaningTypeText = 'Cleaning';
  }

  const handlePress = () => {
    if (onPress) onPress();
  };

  

  return (
    <div className="unfinished-scheduling-container" onClick={handlePress}>
      <div className="unfinished-scheduling-header">
        <span className="unfinished-scheduling-header-text">Unfinished Scheduling</span>
      </div>
      {property && (
        <div className="unfinished-scheduling-address-row">
          <span className="unfinished-scheduling-address-text">{property.address}</span>
        </div>
      )}
      <div className="unfinished-scheduling-cleaning-type-row">
        <span className="unfinished-scheduling-cleaning-type-text">{cleaningTypeText}</span>
      </div>
      {items && items.length > 0 && (
        <div className="unfinished-scheduling-padding">
          <ImageRow
            items={items}
            isNavigationEnabled={false}
            targetScreen="ToDoListScreen"
            userType="cleaner"
            placeholderImageType="checklist"
          />
        </div>
      )}
    </div>
  );
};

export default UnfinishedScheduling;
