// src/components/CleanerBlock.js

import React from 'react';
import './CleanerBlock.css';

const CleanerBlock = ({ cleaner, onPress, selected = false }) => {
  const formattedName = cleaner.firstName.length > 10 ? `${cleaner.firstName.substring(0, 10)}…` : cleaner.firstName;

  return (
    <div className="wrapper" onClick={onPress}>
      <div className={`block ${selected ? 'selectedBlock' : ''}`}>
        {cleaner.userProfilePhoto ? (
          <img src={cleaner.userProfilePhoto} alt="Profile" className="profilePhoto" />
        ) : (
          <span className="text">{cleaner.cleanerUserId}</span>
        )}
      </div>
      <div className="labelContainer">
        <span className="labelText">{formattedName}</span>
      </div>
    </div>
  );
};

export default CleanerBlock;
