import React from 'react';
import styles from './CustomHeader.module.css'; // Make sure to create this module

const CustomHeader = ({ backgroundColor, textColor, text, fontSize = '1.1rem' }) => {
  const headerStyle = {
    backgroundColor,
    color: textColor,
    fontSize,
    padding: '5px 0',
    // margin: '0 0 5px 0',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '5px',
    width: '100%', // this ensures it takes up the full width of its container
  };

  return (
    <div style={headerStyle} className={styles.customHeader}>
      {text}
    </div>
  );
};

export default CustomHeader;
