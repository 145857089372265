import React from 'react';
import './UploadProgress.css';

function UploadProgress({ isUploading, progressPercent }) {
  if (!isUploading && progressPercent === 0) {
    return null; // Do not render anything if not uploading
  }

  return (
    <div className="upload-progress">
      <span className="upload-text">
        {progressPercent === 100 ? 'Upload Complete' : `Uploading Image: ${progressPercent}%`}
      </span>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
    </div>
  );
}

export default UploadProgress;



// import React from 'react';
// import './UploadProgress.css';

// function UploadProgress({ isUploading, progressPercent }) {
//   return (
//     <div className="upload-progress">
//       <span className="upload-text">
//         {isUploading ? `Uploading Image: ${progressPercent}%` : 'Ready to Upload'}
//       </span>
//       <div className="progress-bar-container">
//         <div
//           className="progress-bar"
//           style={{ width: `${isUploading ? progressPercent : 0}%` }}
//         ></div>
//       </div>
//     </div>
//   );
// }

// export default UploadProgress;
