// src/slices/desiredRecurringDaySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const desiredRecurringDaySlice = createSlice({
  name: 'desiredRecurringDay',
  initialState,
  reducers: {
    setRecurringDay: (state, action) => {
      return action.payload;
    },
    resetRecurringDay: () => {
      return '';
    },
  },
});

export const { setRecurringDay, resetRecurringDay } = desiredRecurringDaySlice.actions;
export default desiredRecurringDaySlice.reducer;
