// src/screens/admin/RecurringJobsStatus.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import Breadcrumbs from '../../components/admin/Breadcrumbs';
import HoverTable from '../../components/admin/HoverTable';
import SectionHeader from '../../components/SectionHeader';


import Header from '../../components/Header';
import { apiBaseUrl } from '../../apiConfig';
import { getFirebaseToken } from '../../utils/firebaseTokenUtil';

const breadcrumbs = [
  { name: 'Home', route: '/adminHome' },
  { name: 'Recurring Job Status', route: '/recurring-job-status' },
];

const recurringJobHeaders = [
  'Address',
  'Service Frequency',
  'Period Frequency',
  'Property Type',
  'Square Feet',
  '# Bedrooms',
  '# Cleaners',
];

const RecurringJobsStatus = () => {
  const navigate = useNavigate();
  const [recurringJobsData, setRecurringJobsData] = useState([]);





  const getRecurringJobData = (recurringJobs) => {
    return recurringJobs.map((job) => ({
      rowContent: [
        { type: 'text', content: job.address || 'N/A' },
        { type: 'text', content: job.serviceFrequency || 'N/A' },
        { type: 'text', content: job.periodFrequencyLabel || 'N/A' },
        { type: 'text', content: job.propertyType || 'N/A' },
        { type: 'text', content: job.squareFeet?.toString() || 'N/A' },
        { type: 'text', content: job.numberBedrooms?.toString() || 'N/A' },
        { type: 'text', content: job.numberOfCleaners?.toString() || 'N/A' },
      ],
      recurringJobId: job.recurringJobId,
    }));
  };



 // Fetch recurring jobs
 useEffect(() => {
    const fetchRecurringJobs = async () => {
      try {
        const firebaseIdToken = await getFirebaseToken();
        if (!firebaseIdToken) throw new Error('No Firebase ID token');

        const response = await axios.get(`${apiBaseUrl}/admin/fetch-recurring-jobs`, {
          headers: { Authorization: `Bearer ${firebaseIdToken}` },
        });

        if (response.status === 200) {
          setRecurringJobsData(response.data);
        } else {
          setRecurringJobsData([]);
        }
      } catch (error) {
        console.error('Error fetching recurring jobs:', error);
        setRecurringJobsData([]);
      }
    };

    fetchRecurringJobs();
  }, []);
  
  




  const handleRecurringJobClick = (job) => {
    navigate(`/recurring-job-details/${job.recurringJobId}`);
  };






  return (
    <div className="full-width-container">
      <Header />
      <div className="content-container column">
        <h1>Recurring Jobs</h1>
        <Breadcrumbs crumbs={breadcrumbs} />

        {recurringJobsData.length > 0 ? (
          <>
            <SectionHeader
              text="Recurring Jobs"
              backgroundColor="black"
              fontColor="white"
              marginTop={20}
              marginBottom={5}
              padding={5}
            />


          <HoverTable
            headers={recurringJobHeaders}
            data={getRecurringJobData(recurringJobsData).map((job) => job.rowContent)}
            onRowClick={(index) => handleRecurringJobClick(recurringJobsData[index])}
          />



          </>
        ) : (
          <div className="no-jobs-message">No recurring jobs found</div>
        )}

      </div>
    </div>
  );
};

export default RecurringJobsStatus;
