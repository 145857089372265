// src/store/index.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage'; // default storage is `localStorage` for web


import userReducer from '../slices/userSlice';
import customerUserReducer from '../slices/customerUserSlice';
import jobReducer from '../slices/jobSlice';
import propertyReducer from '../slices/propertySlice';
import propertiesReducer from '../slices/propertiesSlice';
import assignedPropertiesReducer from '../slices/assignedPropertiesSlice';
import scheduleSlotsReducer from '../slices/scheduleSlotsSlice';
import tasksReducer from '../slices/tasksSlice';
import subscribedTasksReducer from '../slices/subscribedTasksSlice';
import subscribedCleanerPhotosReducer from '../slices/subscribedCleanerPhotosSlice';
import desiredCleanersReducer from '../slices/desiredCleanersSlice';
import desiredRecurringDayReducer from '../slices/desiredRecurringDaySlice';
import desiredDateReducer from '../slices/desiredDateSlice';
import serviceFrequencyReducer from '../slices/serviceFrequencySlice';
import availableRecurringDaysReducer from '../slices/availableRecurringDaysSlice';
import recurringSoloDayCleanersReducer from '../slices/recurringSoloDayCleanersSlice';
import recurringMultiDayCleanersReducer from '../slices/recurringMultiDayCleanersSlice';
import selectedCardInformationReducer from '../slices/selectedCardInformationSlice';
import subscribedRoomDataReducer from '../slices/subscribedRoomDataSlice';
import navigationReducer from '../slices/navigationSlice';
import allChecklistItemsReducer from '../slices/allChecklistItemsSlice';
import authReducer from '../slices/authSlice';
import adminTargetUserReducer from '../slices/adminTargetUserSlice';


// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  customerUser: customerUserReducer,
  auth: authReducer,
  job: jobReducer,
  property: propertyReducer,
  properties: propertiesReducer,
  allChecklistItems: allChecklistItemsReducer,
  assignedProperties: assignedPropertiesReducer,
  scheduleSlots: scheduleSlotsReducer,
  tasks: tasksReducer,
  subscribedTasks: subscribedTasksReducer,
  subscribedCleanerPhotos: subscribedCleanerPhotosReducer,
  desiredCleaners: desiredCleanersReducer,
  desiredRecurringDay: desiredRecurringDayReducer,
  desiredDate: desiredDateReducer,
  serviceFrequency: serviceFrequencyReducer,
  availableRecurringDays: availableRecurringDaysReducer,
  recurringSoloDayCleaners: recurringSoloDayCleanersReducer,
  recurringMultiDayCleaners: recurringMultiDayCleanersReducer,
  selectedCardInformation: selectedCardInformationReducer,
  subscribedRoomData: subscribedRoomDataReducer,
  navigation: navigationReducer,
  adminTargetUser: adminTargetUserReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Create the persistor object
export const persistor = persistStore(store);

export default store;