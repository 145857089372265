// src/slices/recurringMultiDayCleanersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const recurringMultiDayCleanersSlice = createSlice({
  name: 'recurringMultiDayCleaners',
  initialState,
  reducers: {
    setRecurringMultiDayCleaners: (state, action) => {
      return action.payload;
    },
    resetRecurringMultiDayCleaners: () => {
      return [];
    },
  },
});

export const { setRecurringMultiDayCleaners, resetRecurringMultiDayCleaners } = recurringMultiDayCleanersSlice.actions;
export default recurringMultiDayCleanersSlice.reducer;
