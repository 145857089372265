// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load the Stripe public key
const stripePromise = loadStripe('pk_live_51LnvNrItngxLrmLmUhI7achEVqDX4gzMxAZZdCbotlElMPj91bkro2AYG7fwu4aYjZEdnmKMQb1xBFl8Afb2WYYg00427Y5p1J');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </Router>
    </PersistGate>
  </Provider>
);



