import React from 'react';
import styles from './DataDisplayTable.module.css'; // Make sure this module exists and is correctly linked

const DataDisplayTable = ({ dataArray }) => {
  const renderData = (item) => {
    if (item.type === 'phone') {
      // Format phone number and render as a tel link
      const formatPhoneNumber = (phoneNumber) => {
        const match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
        return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
      };
      return (
        <a href={`tel:${item.data}`} style={{ color: 'blue', textDecoration: 'none' }}>
          {formatPhoneNumber(item.data)}
        </a>
      );
    } else if (item.type === 'email') {
      // Render as a mailto link
      return (
        <a href={`mailto:${item.data}`} style={{ color: 'blue', textDecoration: 'none' }}>
          {item.data}
        </a>
      );
    }
    // Default case: Render as plain text
    return item.data;
  };

  return (
    <table className={styles.dataTable}>
      <tbody>
        {dataArray.map((item, index) => (
          <tr key={index} className={index % 2 === 0 ? styles.oddRow : styles.evenRow}>
            <td className={styles.nameColumn}>{item.name}</td>
            <td className={styles.dataColumn}>{renderData(item)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataDisplayTable;




// // DataDisplayTable.js
// import React from 'react';
// import styles from './DataDisplayTable.module.css'; // Make sure this module exists and is correctly linked

// const DataDisplayTable = ({ dataArray }) => {
//   const renderData = (data) => {
//     if (Array.isArray(data)) {
//       return data.map((item, index) => <div key={index}>{item}</div>);
//     }
//     return data;
//   };

//   return (
//     <table className={styles.dataTable}>
//       <tbody>
//         {dataArray.map((item, index) => (
//           <tr key={index} className={index % 2 === 0 ? styles.oddRow : styles.evenRow}>
//             <td className={styles.nameColumn}>{item.name}</td>
//             <td className={styles.dataColumn}>{renderData(item.data)}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };

// export default DataDisplayTable;
