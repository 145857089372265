// src/slices/subscribedTasksSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const subscribedTasksSlice = createSlice({
  name: 'subscribedTasks',
  initialState,
  reducers: {
    addOrUpdateTasks: (state, action) => {
      const tasks = action.payload;
      for (const [taskId, taskData] of Object.entries(tasks)) {
        state[taskId] = taskData; // Store the entire task object
      }
    },
    removeTasks: (state, action) => {
      action.payload.forEach(taskId => {
        delete state[taskId]; // Remove the task
      });
    }
  },
});

export const { addOrUpdateTasks, removeTasks } = subscribedTasksSlice.actions;
export default subscribedTasksSlice.reducer;
