import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  id: null,
  accountCreationDate: null,
  firstName: null,
  lastName: null,
  role: null,
  phone: null,
  phoneVerified: false,
  email: null,
};

// Create adminTargetUser slice
const adminTargetUserSlice = createSlice({
  name: 'adminTargetUser',
  initialState,
  reducers: {
    updateAdminTargetUser: (state, action) => {
      // Update state with payload data
      Object.keys(action.payload).forEach(key => {
        if (action.payload[key] !== undefined) {
          state[key] = action.payload[key];
        }
      });
    },
    resetAdminTargetUserState: (state) => {
      Object.assign(state, initialState); // Reset state to initial values
    },
  },
});

export const { updateAdminTargetUser, resetAdminTargetUserState } = adminTargetUserSlice.actions;
export default adminTargetUserSlice.reducer;