// src/components/CalendarComponent.js
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarComponent.css';

function CalendarComponent({ availableDates = [], onDateSelected, initialSelectedDate  }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());


  useEffect(() => {
    if (availableDates.length > 0) {
      if (initialSelectedDate && availableDates.includes(initialSelectedDate)) {
        console.log("initialSelectedDate", initialSelectedDate);
        setSelectedDate(new Date(initialSelectedDate));
      } else {
        setSelectedDate(null);
      }
    }
  }, [initialSelectedDate, availableDates]);




  useEffect(() => {
    if (availableDates.length > 0) {
      // Parse the first available date to a Date object
      const firstAvailableDate = new Date(availableDates[0]);
      
      // Check if the first available date is in a different month
      if (
        firstAvailableDate.getFullYear() !== currentMonth.getFullYear() ||
        firstAvailableDate.getMonth() !== currentMonth.getMonth()
      ) {
        setCurrentMonth(firstAvailableDate); // Update currentMonth to the first available date's month
      }
    }
  }, [availableDates]);




  const onDateChange = (date) => {
    const dateString = date.toISOString().split('T')[0];
    if (availableDates.includes(dateString)) {
      setSelectedDate(date);
      console.log("Marked date selected:", dateString);

      // Call the onDateSelected function if it's provided
      if (onDateSelected) {
        onDateSelected(dateString);
      }
    } else {
      console.log("Selection blocked - unmarked date:", dateString);
    }
  };

  // const tileClassName = ({ date, view }) => {
  //   if (view === 'month') {
  //     const dateString = date.toISOString().split('T')[0];
  //     if (date.toDateString() === new Date().toDateString()) return 'today-date';
  //     if (availableDates.includes(dateString)) return 'marked-date';
  //     return 'disabled-date';
  //   }
  //   return null;
  // };


  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];
      if (selectedDate && dateString === selectedDate.toISOString().split('T')[0]) {
        return 'activeDate';
      }
      if (date.toDateString() === new Date().toDateString()) return 'today-date';
      if (availableDates.includes(dateString)) return 'marked-date';
      return 'disabled-date';
    }
    return null;
  };




  const tileDisabled = ({ date, view }) => {
    const dateString = date.toISOString().split('T')[0];
    return view === 'month' && !availableDates.includes(dateString);
  };

  return (
    <div className="calendar-container">
      <Calendar
        onChange={onDateChange}
        value={selectedDate}
        tileClassName={tileClassName}
        tileDisabled={tileDisabled}
        activeStartDate={currentMonth}
        onActiveStartDateChange={({ activeStartDate }) => setCurrentMonth(activeStartDate)}
      />
    </div>
  );
}

export default CalendarComponent;
