// src/slices/selectedCardInformationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const selectedCardInformationSlice = createSlice({
  name: 'selectedCardInformation',
  initialState,
  reducers: {
    setCardInformation: (state, action) => {
      return action.payload;
    },
    resetCardInformation: () => {
      return null;
    },
  },
});

export const { setCardInformation, resetCardInformation } = selectedCardInformationSlice.actions;
export default selectedCardInformationSlice.reducer;
