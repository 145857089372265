// src/slices/propertySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  userId: null,
  geographicRegionId: null,
  supplyCenterDriveTime: null,    
  supplyCenterDriveMiles: null,     
  propertyName: null,
  defaultProperty: false,
  date: null,
  propertyType: null,
  apartmentUnit: null,
  apartmentName: null,
  placeId: null,
  longitude: null,    
  latitude: null,     
  address: null,
  formattedAddress: null,
  zipCode: null,
  city: null,
  propertyState: null,        
  meetOwnerAtDoorBinary: false,
  hiddenKeyBinary: false,
  hiddenKeyPhotoId: null,
  unlockedBinary: false,
  gateCommunityBinary: false,
  numberBedrooms: null,
  numberBathrooms: null,
  numberHalfBathrooms: null,
  numberCeilingFans: null,
  nonMiniBlindsBinary: false,
  squareFeet: null,
  propertyAccessNotes: null,
  numberDogs: null,
  numberCats: null,
  propertyPetsNotes: null,
  deletedBinary: false,
};


const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updateProperty: (state, action) => {
      // Check for undefined payload
      if (action.payload === undefined) {
        console.error('Payload is undefined!');
        return; // Early return if payload is undefined
      }

      // Dynamically update state properties
      Object.entries(action.payload).forEach(([key, value]) => {
        if (value !== undefined && key in state) {
          state[key] = value;
        } else {
          // Optionally log an error or handle cases where key is not found in the state
          // console.error(`Key '${key}' not found in state or value is undefined`);
        }
      });
    },

    updateOrResetProperty: (state, action) => {
      // Log the incoming payload to see if propertyName is included and what its value is
          
      Object.keys(initialState).forEach(key => {
        state[key] = action.payload[key] !== undefined ? action.payload[key] : initialState[key];
      });    

    },
    

  },
});

export const { updateProperty, updateOrResetProperty } = propertySlice.actions;
export default propertySlice.reducer;