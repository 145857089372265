// src/slices/assignedPropertiesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  assignedPropertyIds: []
};

const assignedPropertiesSlice = createSlice({
  name: 'assignedProperties',
  initialState,
  reducers: {
    addAssignedPropertyId: (state, action) => {
      state.assignedPropertyIds.push(action.payload);
    },
    removeAssignedPropertyId: (state, action) => {
      state.assignedPropertyIds = state.assignedPropertyIds.filter(id => id !== action.payload);
    },
    setAssignedPropertyIds: (state, action) => {
      state.assignedPropertyIds = action.payload;
    }
  },
});

export const { addAssignedPropertyId, removeAssignedPropertyId, setAssignedPropertyIds } = assignedPropertiesSlice.actions;
export default assignedPropertiesSlice.reducer;
