import React from 'react';
import { GoogleMap, useJsApiLoader, MarkerF as Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const GoogleMapComponent = ({ location }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDjVARCYQNdHvEwZH9rLxohTT7SMjzpOWw"
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={location}
      zoom={12}
    >
      <Marker position={location} />
    </GoogleMap>
  );
}

export default GoogleMapComponent;
