// src/screens/admin/CustomerLookup.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Breadcrumbs from '../../components/admin/Breadcrumbs';
import Header from '../../components/Header';
import HoverTable from '../../components/admin/HoverTable';
import SectionHeader from '../../components/SectionHeader';


import InputMask from 'react-input-mask';
import { apiBaseUrl } from '../../apiConfig';
import { getFirebaseToken } from '../../utils/firebaseTokenUtil';
import { updateAdminTargetUser, resetAdminTargetUserState } from '../../slices/adminTargetUserSlice'; 



const breadcrumbs = [
  { name: 'Dashboard', route: '/adminHome' },
  { name: 'Customer Lookup', route: '/customer-lookup' },
];


const formatSuggestion = (suggestion) => {
  // Format the phone number from E.164 to (XXX) XXX-XXXX
  const formattedPhone = suggestion.phone
    ? `(${suggestion.phone.slice(2, 5)}) ${suggestion.phone.slice(5, 8)}-${suggestion.phone.slice(8)}`
    : 'N/A';

  // Format name fields or fallback to "No Name"
  const firstName = suggestion.firstName?.trim() || '';
  const lastName = suggestion.lastName?.trim() || '';
  const name = firstName || lastName ? `${firstName} ${lastName}`.trim() : 'No Name';

  // Verified status
  const verifiedStatus = suggestion.phoneVerified ? 'verified' : 'unverified';

  // Email or render nothing if empty
  const email = suggestion.email?.trim() ? ` / ${suggestion.email}` : '';

  // Add ID if it exists
  const id = suggestion.id ? ` / ${suggestion.id}` : '';

  return `${formattedPhone} / ${name} / ${verifiedStatus}${email}${id}`;
};


const CustomerLookup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state for admin target user
  const adminTargetUser = useSelector((state) => state.adminTargetUser);

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [phoneSuggestions, setPhoneSuggestions] = useState([]);
  const [nameSuggestions, setNameSuggestions] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [recurringJobsData, setRecurringJobsData] = useState([]);


  const [jobType, setJobType] = useState('active');  


  useEffect(() => {
    if (adminTargetUser.id) {
      fetchJobsForUser(adminTargetUser.id, jobType);
      fetchRecurringJobsForUser(adminTargetUser.id);
    }
  }, []); 


  const getHeaders = () => {
    if (jobType === 'completed') {
      return [
        'Status',
        'Job Date',
        'Clock Status',
        '# Cleaners',
        'Est. Clean Time',
        'Customer Name',
        'Address',
        'Cleaning Type',
      ];
    } else if (jobType === 'deleted') {
      return [
        'Status',
        'Job Date',
        '# Cleaners',
        'Est. Clean Time',
        'Customer Name',
        'Address',
        'Cleaning Type',
      ];
    }
    return [
      'Payment Intent Status',
      'Job Date',
      'Clock Status',
      '# Cleaners',
      'Est. Clean Time',
      'Customer Name',
      'Address',
      'Cleaning Type',
    ];
  };

  const getJobStatusData = () => {
    return jobsData.map((job) => {
      if (jobType === 'completed') {
        return {
          rowContent: [
            { type: 'text', content: 'Completed' },
            { type: 'text', content: job.date },
            { type: 'text', content: job.cleanerClockStatus },
            { type: 'text', content: job.numberOfCleaners.toString() },
            { type: 'text', content: `${job.estimatedCleanTime} hrs` },
            { type: 'text', content: job.customerName },
            { type: 'text', content: job.address },
            { type: 'text', content: job.cleaningType },
          ],
          jobId: job.jobId,
        };
      } else if (jobType === 'deleted') {
        return {
          rowContent: [
            { type: 'text', content: 'Deleted' },
            { type: 'text', content: job.date },
            { type: 'text', content: job.numberOfCleaners.toString() },
            { type: 'text', content: `${job.estimatedCleanTime} hrs` },
            { type: 'text', content: job.customerName },
            { type: 'text', content: job.address },
            { type: 'text', content: job.cleaningType },
          ],
          jobId: job.jobId,
        };
      }
      return {
        rowContent: [
          { type: 'text', content: job.paymentIntentStatus },
          { type: 'text', content: job.date },
          { type: 'text', content: job.cleanerClockStatus },
          { type: 'text', content: job.numberOfCleaners.toString() },
          { type: 'text', content: `${job.estimatedCleanTime} hrs` },
          { type: 'text', content: job.customerName },
          { type: 'text', content: job.address },
          { type: 'text', content: job.cleaningType },
        ],
        jobId: job.jobId,
      };
    });
  };


  const recurringJobHeaders = [
    'Address',
    'Service Frequency',
    'Period Frequency',
    'Property Type',
    'Square Feet',
    '# Bedrooms',
    '# Cleaners',
  ];


  const getRecurringJobData = (recurringJobs) => {
    return recurringJobs.map((job) => ({
      rowContent: [
        { type: 'text', content: job.address },
        { type: 'text', content: job.serviceFrequency },
        { type: 'text', content: job.periodFrequencyLabel },
        { type: 'text', content: job.propertyType },
        { type: 'text', content: job.squareFeet.toString() },
        { type: 'text', content: job.numberBedrooms.toString() },
        { type: 'text', content: job.numberOfCleaners.toString() },
      ],
      recurringJobId: job.recurringJobId, // Add recurring job ID here
    }));
  };  


  const handleJobTypeChange = (e) => {
    const newJobType = e.target.value;
    setJobType(newJobType);
  
    if (adminTargetUser.id) {
      fetchJobsForUser(adminTargetUser.id, newJobType);
    }
  };  


  const handlePhoneChange = async (value) => {
    setPhone(value);

    if (!value.trim()) {
      setPhoneSuggestions([]);
      return;
    }

    try {
      const token = await getFirebaseToken();
      const response = await axios.post(
        `${apiBaseUrl}/admin/phone-lookup`,
        { phoneNumber: value },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setPhoneSuggestions(response.data || []); // Update suggestions with the API response
      }
    } catch (error) {
      console.error('Error during phone lookup:', error);
      setPhoneSuggestions([]); // Clear suggestions in case of an error
    }
  };


  const handleNameChange = async (value) => {
    setName(value);
  
    if (!value.trim()) {
      setNameSuggestions([]);
      return;
    }
  
    try {
      const firebaseIdToken = await getFirebaseToken();
      if (!firebaseIdToken) throw new Error('No Firebase ID token');
  
      const response = await axios.post(
        `${apiBaseUrl}/admin/name-lookup`,
        { name: value },
        { headers: { Authorization: `Bearer ${firebaseIdToken}` } }
      );
  
      if (response.status === 200) {
        setNameSuggestions(response.data);

      } else {
        setNameSuggestions([]);
      }
    } catch (error) {
      console.error('Error during name lookup:', error);
      setNameSuggestions([]);
    }
  };  

  
  const fetchJobsForUser = async (userId, jobType) => {
    try {
      const token = await getFirebaseToken();

      const params = {
        geographicRegionId: 1, 
        userId,
      };
  
      if (jobType === 'completed') {
        params.completedJobsOnly = true;
      } else if (jobType === 'deleted') {
        params.deletedJobsOnly = true;
      }
  
      const response = await axios.get(`${apiBaseUrl}/admin/fetch-jobs`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
  
      if (response.status === 200) {
        setJobsData(response.data);
      } else {
        setJobsData([]);
      }
    } catch (error) {
      console.error('Error fetching jobs for user:', error);
      setJobsData([]);
    }
  };


  const fetchRecurringJobsForUser = async (userId) => {
    try {
      const token = await getFirebaseToken();
      const response = await axios.get(`${apiBaseUrl}/admin/fetch-recurring-jobs`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { userId },
      });
  
      if (response.status === 200) {
        setRecurringJobsData(response.data);
      } else {
        setRecurringJobsData([]);
      }
    } catch (error) {
      console.error('Error fetching recurring jobs:', error);
      setRecurringJobsData([]);
    }
  };  
  

  const handleSuggestionClick = async (suggestion) => {
    console.log('Suggestion Clicked:', suggestion);
  
    // Clear suggestions
    setPhone('');
    setPhoneSuggestions([]);
    setName('');
    setNameSuggestions([]);
  
    // Reset jobs data
    setJobsData([]);
    setRecurringJobsData([]);
  
    // Update admin target user in Redux
    dispatch(updateAdminTargetUser(suggestion));
  
    // Fetch jobs and recurring jobs for the selected user
    fetchJobsForUser(suggestion.id, jobType);
    fetchRecurringJobsForUser(suggestion.id);
  };   


  const handleRowClick = (job) => {
    console.log('Job clicked:', job);
    navigate(`/job-statuses/${job.jobId}`); // Navigate to job detail page
  };


  const handleRecurringJobClick = (job) => {
    if (!job.recurringJobId) {
      console.error("Recurring Job ID is missing:", job);
      return;
    }
  
    // Navigate to the RecurringJobDetailPage with the recurringJobId
    navigate(`/recurring-job-details/${job.recurringJobId}`);
  };


  const getJobTypeText = () => {
    switch (jobType) {
      case 'completed':
        return 'Completed Jobs';
      case 'deleted':
        return 'Deleted Jobs';
      default:
        return 'Scheduled Jobs';
    }
  };
  

  const getNoJobsMessage = () => {
    switch (jobType) {
      case 'completed':
        return 'No completed jobs found';
      case 'deleted':
        return 'No deleted jobs found';
      default:
        return 'No active jobs found';
    }
  };

  
  return (
    <div className="full-width-container">
      <Header />

      <div className="content-container column">
        <Breadcrumbs crumbs={breadcrumbs} />
        <h1>Customer Lookup</h1>
  

        <div className='cust-lookup-container'>
          <div>
            <label className="form-label">Phone Number Lookup:</label>
            <InputMask
              mask="(999) - 999 - 9999"
              value={phone}
              onChange={(e) => handlePhoneChange(e.target.value)}
              placeholder="(123) - 456 - 7890"
            >
              {(inputProps) => <input {...inputProps} type="tel" className="phone-input" />}
            </InputMask>
    
            <section className="suggestions-container">
              {phoneSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {formatSuggestion(suggestion)}
                </div>
              ))}
            </section>
          </div>
    
          <div>
            <label className="form-label">Name Lookup:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              placeholder="Enter a name"
              className="cust-lookup-name-input"
            />
    
            <section className="suggestions-container">
              {nameSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {formatSuggestion(suggestion)}
                </div>
              ))}
            </section>
          </div>

          <div>
            <label className="form-label">Job Type:</label>
            <select value={jobType} onChange={handleJobTypeChange} className="cust-lookup-select-option">
              <option value="active">Active Jobs</option>
              <option value="completed">Completed Jobs</option>
              <option value="deleted">Deleted Jobs</option>
            </select>
          </div>

        </div>


        {adminTargetUser.id ? (
          <>
            {/* Recurring Jobs Section */}
            {recurringJobsData.length > 0 ? (
              <>
                <SectionHeader
                  text="Recurring Jobs"
                  backgroundColor="black"
                  fontColor="white"
                  marginTop={20}
                  marginBottom={5}
                  padding={5}
                />
                <HoverTable
                  headers={recurringJobHeaders}
                  data={getRecurringJobData(recurringJobsData).map((job) => job.rowContent)}
                  onRowClick={(index) => handleRecurringJobClick(recurringJobsData[index])}
                />
              </>
            ) : (
              <div className="no-jobs-message">No recurring jobs found</div>
            )}



            {/* Regular Jobs Section */}
            {jobsData.length > 0 ? (
              <>
                <SectionHeader
                  text={getJobTypeText()} // Dynamic title based on jobType
                  backgroundColor="black"
                  fontColor="white"
                  marginTop={20}
                  marginBottom={5}
                  padding={5}
                />
                <HoverTable
                  headers={getHeaders()}
                  data={getJobStatusData().map((job) => job.rowContent)}
                  onRowClick={(index) => handleRowClick(jobsData[index])}
                />
              </>
            ) : (
              <div className="no-jobs-message">{getNoJobsMessage()}</div>
            )}
          </>
        ) : (
          <div className="no-jobs-message">Please select a user to view their jobs.</div>
        )}
        

      </div>
    </div>
  );
  


};

export default CustomerLookup;
