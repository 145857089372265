// src/components/MultiCleanerContainer.js

import React, { useState, useEffect } from 'react';
import CleanerBlock from './CleanerBlock';
import UnselectedCleanerBlock from './UnselectedCleanerBlock';
import './MultiCleanerContainer.module.css';

const MultiCleanerContainer = ({ cleaners, numberOfCleaners, onSelectedCleanersChange }) => {
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const [morningCleaners, setMorningCleaners] = useState([]);
  const [afternoonCleaners, setAfternoonCleaners] = useState([]);

  const isSelected = (cleaner) => {
    return selectedCleaners.some(selectedCleaner => 
        selectedCleaner.cleanerUserId === cleaner.cleanerUserId && 
        selectedCleaner.shiftSlot === cleaner.shiftSlot
    );
  };

  const reorderCleaners = (combinedCleaners, selectedCleaners) => {
    const orderedCleaners = selectedCleaners.map(selCleaner => 
      combinedCleaners.find(combCleaner => combCleaner.cleanerUserId === selCleaner.cleanerUserId)
    );
    combinedCleaners.forEach(cleaner => {
      if (!orderedCleaners.some(ordCleaner => ordCleaner && ordCleaner.cleanerUserId === cleaner.cleanerUserId)) {
        orderedCleaners.push(cleaner);
      }
    });
    return orderedCleaners;
  };

  const getMatchingArrays = (mainArrays, criteriaArray) => {
    return mainArrays.filter(singleArray => 
      criteriaArray.every(criteria => 
        singleArray.some(cleaner => cleaner.cleanerUserId === criteria.cleanerUserId)
      )
    );
  };

  const updateSelectedCleanersList = (currentSelectedCleaners, cleanerToToggle) => {
    let wasSelected = false;
    const updatedCleaners = currentSelectedCleaners.filter(existingCleaner => {
        if (existingCleaner.cleanerUserId === cleanerToToggle.cleanerUserId && existingCleaner.shiftSlot === cleanerToToggle.shiftSlot) {
            wasSelected = true;
            return false;
        }
        return true;
    });
    if (!wasSelected) {
        updatedCleaners.push(cleanerToToggle);
    }
    return updatedCleaners;
  };

  const maxCleanersSelected = (cleaner, selectedCleaners, numberOfCleaners) => {
    const isCleanerAlreadySelected = selectedCleaners.some(existingCleaner => 
        existingCleaner.cleanerUserId === cleaner.cleanerUserId && 
        existingCleaner.shiftSlot === cleaner.shiftSlot
    );
    if (!isCleanerAlreadySelected && selectedCleaners.length >= numberOfCleaners) {
        alert('Please remove a cleaner before trying to add another.');
        return true;
    }
    return false;
  };

  const handleCleanerSelection = (cleaner) => {
    if (maxCleanersSelected(cleaner, selectedCleaners, numberOfCleaners)) {
      return;
    }
    const updatedSelectedCleaners = updateSelectedCleanersList(selectedCleaners, cleaner);
    setSelectedCleaners(updatedSelectedCleaners);

    if (updatedSelectedCleaners.length === 0) {
      processCleaners(cleaners);
    } else {
      const slot = cleaner.shiftSlot;
      if (slot === 'morning' && cleaners.Morning) {
        const matchingMorningCleaners = getMatchingArrays(cleaners.Morning, updatedSelectedCleaners);
        setMorningCleaners(reorderCleaners(combineCleaners(matchingMorningCleaners), updatedSelectedCleaners));
        setAfternoonCleaners([]);
      }
      if (slot === 'afternoon' && cleaners.Afternoon) {
        const matchingAfternoonCleaners = getMatchingArrays(cleaners.Afternoon, updatedSelectedCleaners);
        setAfternoonCleaners(reorderCleaners(combineCleaners(matchingAfternoonCleaners), updatedSelectedCleaners));
        setMorningCleaners([]);
      }      
    }
  };

  const combineCleaners = (nestedArrays) => {
    return nestedArrays.flat().reduce((acc, cleaner) => {
      if (!acc.find(item => item.cleanerUserId === cleaner.cleanerUserId)) {
        acc.push(cleaner);
      }
      return acc;
    }, []);
  };

  const processCleaners = (cleaners) => {
    setSelectedCleaners([]);
    if (cleaners.Morning) setMorningCleaners(combineCleaners(cleaners.Morning));
    if (cleaners.Afternoon) setAfternoonCleaners(combineCleaners(cleaners.Afternoon));
  };

  useEffect(() => {
    onSelectedCleanersChange(selectedCleaners);
  }, [selectedCleaners]);

  useEffect(() => {
    processCleaners(cleaners);
  }, [cleaners]);

  return (
    <div className="container">
      <span className="titleText">Unselected cleaners will be chosen by Sparkle:</span>
      <div className="row">
        {selectedCleaners.map((cleaner, index) => (
          <CleanerBlock
            key={`selected-${index}`}
            cleaner={cleaner}
            selected={true}
            onPress={() => handleCleanerSelection(cleaner)}
          />
        ))}
        {Array.from({ length: numberOfCleaners - selectedCleaners.length }).map((_, index) => (
          <UnselectedCleanerBlock key={`unselected-${index}`} />
        ))}
      </div>

      {morningCleaners.length > 0 && (
        <>
          <span className="titleText">Morning Cleaner Options:</span>
          <div className="row">
            {morningCleaners.map((cleaner, index) => (
              <CleanerBlock
                key={`morning-${index}`}
                cleaner={cleaner}
                selected={isSelected(cleaner)}
                onPress={() => handleCleanerSelection(cleaner)}
              />
            ))}
          </div>
        </>
      )}

      {afternoonCleaners.length > 0 && (
        <>
          <span className="titleText">Afternoon Cleaner Options:</span>
          <div className="row">
            {afternoonCleaners.map((cleaner, index) => (
              <CleanerBlock
                key={`afternoon-${index}`}
                cleaner={cleaner}
                selected={isSelected(cleaner)}
                onPress={() => handleCleanerSelection(cleaner)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MultiCleanerContainer;
