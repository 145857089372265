import React from 'react';
import './ConfirmationDialog.css'; // Include any styling

const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-dialog-overlay">
      <div className="confirmation-dialog">
        <p>{message}</p>
        <div className="dialog-buttons">
          <button className="yes-button" onClick={onConfirm}>Yes</button>
          <button className="no-button" onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
