import React from 'react';
import { ClipLoader } from 'react-spinners';
import styles from './Button.module.css';

const Button = ({ children, onClick, color = 'blue', disabled = false, isLoading = false, width }) => {
  const customColors = {
    green: '#33cc33',
    red: '#ff0000',
    blue: '#007bff',
    gray: '#808080', // Gray color for disabled or loading state
  };

  // Determine the background color based on the `disabled` or `isLoading` state and `color` prop
  const backgroundColor = disabled ? customColors.gray : (customColors[color] || color);

  const buttonStyle = {
    backgroundColor,
    width: width || 'auto',
    cursor: (disabled || isLoading) ? 'not-allowed' : 'pointer',
    position: 'relative',  // Ensure that the spinner can be centered
  };

  return (
    <button
      className={styles.button}
      onClick={!disabled && !isLoading ? onClick : undefined} // Only allow onClick if button is not disabled and not loading
      style={buttonStyle}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <ClipLoader color="#ffffff" size={30} /> {/* Increased size and changed color to white */}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
