import { createSlice } from '@reduxjs/toolkit';

// Initial state with arrays for rooms and roomChecklists
const initialState = {
  rooms: [],
  roomChecklists: [],
};

const findChecklistItemIndex = (checklist, roomId, fieldName) =>
  checklist.findIndex(item => item.roomId === roomId && item.fieldName === fieldName);

const subscribedRoomDataSlice = createSlice({
  name: 'subscribedRoomData',
  initialState,
  reducers: {
    // Existing reducers for rooms
    addOrUpdateRoom: (state, action) => {
      const room = action.payload;
      const index = state.rooms.findIndex(e => e.roomId === room.roomId);

      if (index !== -1) {
        state.rooms[index] = room;
      } else {
        state.rooms.push(room);
      }
    },
    removeRoom: (state, action) => {
      state.rooms = state.rooms.filter(room => room.roomId !== action.payload.roomId);
    },

    // New reducers for checklist items
    addOrUpdateChecklistItem: (state, action) => {
      const { roomId, fieldName, ...rest } = action.payload;
      const index = findChecklistItemIndex(state.roomChecklists, roomId, fieldName);

      if (index !== -1) {
        state.roomChecklists[index] = { roomId, fieldName, ...rest };
      } else {
        state.roomChecklists.push(action.payload);
      }
    },
    removeChecklistItem: (state, action) => {
      const { roomId, fieldName } = action.payload;
      state.roomChecklists = state.roomChecklists.filter(
        item => !(item.roomId === roomId && item.fieldName === fieldName)
      );
    },

    // Resetting state
    resetRoomData: () => {
      console.log('Resetting room data to initial state'); // Log reset action
      return initialState;
    },
  },
});

export const { addOrUpdateRoom, removeRoom, addOrUpdateChecklistItem, removeChecklistItem, resetRoomData } = subscribedRoomDataSlice.actions;

export default subscribedRoomDataSlice.reducer;
