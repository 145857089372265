import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  userId: null,
  propertyId: null,
  recurringJobId: null,  
  scheduleSlotId: null,
  customerRatingId: null,
  dateOrderSubmitted: null,
  cleaningType: null,
  generalNotes: null,
  estimatedCleanTime: null,
  numberOfCleaners: null,
  totalEstimatedCleanTime: null,
  fullShiftOnlyEstimatedCleanTime: null,
  fullShiftOnlyNumberOfCleaners: null,
  regularBasePriceEstimate: null,
  fullShiftBasePriceEstimate: null,
  multiShiftType: null,
  jobDeletedBinary: false,
  jobCompleteBinary: false,
  orderSubmittedBinary: false,
  unscheduledActiveBinary: false,
  createdByRecurringScheduler: false,
};

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    updateJob: (state, action) => {

      if (action.payload === undefined) {
        console.error('Payload is undefined!');
        return; // Early return if payload is undefined
      }

      Object.entries(action.payload).forEach(([key, value]) => {
        if (value !== undefined && key in state) {
          state[key] = value;
        } else {
          // console.error(`Key '${key}' not found in state or value is undefined`);
        }
      });
    },

    updateOrResetJob: (state, action) => {
      Object.keys(initialState).forEach(key => {
        // Update the field if it is in the action payload, else reset to initial state
        state[key] = action.payload[key] !== undefined ? action.payload[key] : initialState[key];
      });
    },

  },
});

export const { updateJob, updateOrResetJob } = jobSlice.actions;
export default jobSlice.reducer;
